import React, { useState, useEffect } from 'react'
import {
  Segment, Button, Grid, Header, Menu, Image, Dropdown, Input
} from 'semantic-ui-react'
import {
  Redirect, Link
} from "react-router-dom"
import { ScannerV2 } from '../Support/ScannerV2'

const REGION_DOMAINS = ['storewalk-np.homedepot.com', 'storepulse-np.homedepot.com']

export const TopMenu = ({ level, setLevel, levelSelection, setLevelSelection, storeNumber, setStoreNumber, storeNumberField, setStoreNumberField, location }) => {
  const [submitted, setSubmitted] = useState(false)
  const isMenuInDevelopment = REGION_DOMAINS.includes(window.location.host) || process.env.NODE_ENV === 'development';

  const path = location.pathname
  const active = path.substring(path.lastIndexOf('/') + 1)
  const [activeItem, setActiveItem] = useState(active ? active : 'overview')

  useEffect(() => {
    setActiveItem(active ? active : 'overview')
  }, [active])

  useEffect(() => {
    setSubmitted(false)
  }, [submitted])

  return (
    <Segment>
      <Grid>
        <Grid.Row>
          <Grid.Column width={5}>
            <Image
              src='/hd_logo.jpg'
              size='mini'
              as={Link}
              to={`/${level}/overview?${level === 'store' ? 'strNbr' : level === 'district' ? 'dstNbr' : 'strNbr'}=${storeNumber}`}
              onClick={() => {
                setActiveItem('overview')
                setStoreNumber(storeNumberField.padStart(4, '0'))
                setLevel(levelSelection)
                setSubmitted(true)
              }
              }
            />
          </Grid.Column>
          <Grid.Column textAlign='center' width={6}>
            <Header textAlign='center'>Store Pulse</Header>
          </Grid.Column>
          <Grid.Column width={5} textAlign='right'>
            <Link
              to={{ pathname: `https://portal.homedepot.com/sites/myapron_en_us/Departments/Assets0001/Pulse%20Tutorial.pdf` }}
              style={{ color: '#EE7125', textDecoration: 'underline' }}
              target="_blank"
            >Store Pulse Tutorial</Link>
          </Grid.Column>
        </Grid.Row>
      </Grid>


      {/* TODO: Move this to the onSubmit function and use history.push to avoid this bolerplate code */}
      {submitted && activeItem === 'overview' && level === 'store' && <Redirect to={`/${level}/overview?strNbr=${storeNumber}`} />}
      {submitted && activeItem === 'overview' && level === 'district' && <Redirect to={`/${level}/overview?dstNbr=${storeNumber}`} />}
      {submitted && activeItem === 'overview' && level === 'region' && <Redirect to={`/${level}/overview?rgnNbr=${storeNumber}`} />}
      {submitted && activeItem === 'overview' && level === 'division' && <Redirect to={`/${level}/overview?divNbr=${storeNumber}`} />}
      {submitted && activeItem === 'orderfulfillment' && level === 'store' && <Redirect to={`/store/orderfulfillment?strNbr=${storeNumber}`} />}
      {submitted && activeItem === 'orderfulfillment' && level === 'district' && <Redirect to={`/district/orderfulfillment?dstNbr=${storeNumber}`} />}
      {submitted && activeItem === 'orderfulfillment' && level === 'region' && <Redirect to={`/region/orderfulfillment?rgnNbr=${storeNumber}`} />}
      {submitted && activeItem === 'orderfulfillment' && level === 'division' && <Redirect to={`/division/orderfulfillment?divNbr=${storeNumber}`} />}
      {submitted && activeItem === 'intraday' && level === 'store' && <Redirect to={`/store/intraday?strNbr=${storeNumber}`} />}
      {submitted && activeItem === 'intraday' && level === 'district' && <Redirect to={`/district/intraday?dstNbr=${storeNumber}`} />}
      {submitted && activeItem === 'intraday' && level === 'region' && <Redirect to={`/region/intraday?rgnNbr=${storeNumber}`} />}
      {submitted && activeItem === 'intraday' && level === 'division' && <Redirect to={`/division/intraday?divNbr=${storeNumber}`} />}
      {submitted && activeItem === 'daily' && level === 'store' && <Redirect to={`/store/daily?strNbr=${storeNumber}`} />}
      {submitted && activeItem === 'daily' && level === 'district' && <Redirect to={`/district/daily?dstNbr=${storeNumber}`} />}
      {submitted && activeItem === 'daily' && level === 'region' && <Redirect to={`/region/daily?rgnNbr=${storeNumber}`} />}
      {submitted && activeItem === 'daily' && level === 'division' && <Redirect to={`/division/daily?divNbr=${storeNumber}`} />}
      {submitted && activeItem === 'credit' && level === 'store' && <Redirect to={`/store/credit?strNbr=${storeNumber}`} />}
      {submitted && activeItem === 'credit' && level === 'district' && <Redirect to={`/district/credit?dstNbr=${storeNumber}`} />}
      {submitted && activeItem === 'credit' && level === 'region' && <Redirect to={`/region/credit?rgnNbr=${storeNumber}`} />}
      {submitted && activeItem === 'credit' && level === 'division' && <Redirect to={`/division/credit?divNbr=${storeNumber}`} />}
      {submitted && activeItem === 'specialtyprorental' && level === 'store' && <Redirect to={`/store/specialtyprorental?strNbr=${storeNumber}`} />}
      {submitted && activeItem === 'specialtyprorental' && level === 'district' && <Redirect to={`/district/specialtyprorental?dstNbr=${storeNumber}`} />}
      {submitted && activeItem === 'specialtyprorental' && level === 'region' && <Redirect to={`/region/specialtyprorental?rgnNbr=${storeNumber}`} />}
      {submitted && activeItem === 'specialtyprorental' && level === 'division' && <Redirect to={`/division/specialtyprorental?divNbr=${storeNumber}`} />}
      {submitted && activeItem.includes('shrink') && level === 'store' && <Redirect to={`/store/shrink?strNbr=${storeNumber}`} />}
      {submitted && activeItem.includes('shrink') && level === 'district' && <Redirect to={`/district/shrink?dstNbr=${storeNumber}`} />}
      {submitted && activeItem.includes('shrink') && level === 'region' && <Redirect to={`/region/shrink?rgnNbr=${storeNumber}`} />}
      {submitted && activeItem.includes('shrink') && level === 'division' && <Redirect to={`/division/shrink?divNbr=${storeNumber}`} />}
      {submitted && activeItem.includes('events') && level === 'store' && <Redirect to={`/store/events?strNbr=${storeNumber}`} />}
      {submitted && activeItem.includes('events') && level === 'district' && <Redirect to={`/district/events?dstNbr=${storeNumber}`} />}
      {submitted && activeItem.includes('events') && level === 'region' && <Redirect to={`/region/events?rgnNbr=${storeNumber}`} />}
      {submitted && activeItem.includes('events') && level === 'division' && <Redirect to={`/division/events?divNbr=${storeNumber}`} />}
      {submitted && activeItem === 'headlinesinsights' && <Redirect to={`/${level}/headlinesinsights?${level === 'store' ? 'strNbr' :
        level === 'district' ? 'dstNbr' : level === 'region' ? 'rgnNbr' : level === 'division' ? 'divNbr' : 'strNbr'}=${storeNumber}`} />}
      <div>&nbsp;</div>
      Select View:
      <Menu fluid attached='top' widths={3} color='orange'>
        <Menu.Item
          as={Link}
          to={`/${level}/overview?${level === 'store' ? 'strNbr' :
            level === 'district' ? 'dstNbr' : level === 'region' ? 'rgnNbr' : level === 'division' ? 'divNbr' : 'strNbr'}=${storeNumber}`}
          name='overview'
          active={activeItem === 'overview'}
          content='Overview'
          onClick={() => {
            setStoreNumber(storeNumberField.padStart(4, '0'))
            setLevel(levelSelection)
            setSubmitted(true)
            setActiveItem('overview')
          }}
        />
        <Menu.Item
          as={Link} to={`/${level}/intraday?${level === 'store' ? 'strNbr' :
            level === 'district' ? 'dstNbr' : level === 'region' ? 'rgnNbr' : level === 'division' ? 'divNbr' : 'strNbr'}=${storeNumber}`}
          name='intraday'
          active={activeItem === 'intraday'}
          content='Hourly Sales'
          onClick={() => {
            setStoreNumber(storeNumberField.padStart(4, '0'))
            setLevel(levelSelection)
            setSubmitted(true)
            setActiveItem('intraday')
          }}
        />
        <Menu.Item
          as={Link} to={`/${level}/daily?${level === 'store' ? 'strNbr' :
            level === 'district' ? 'dstNbr' : level === 'region' ? 'rgnNbr' : level === 'division' ? 'divNbr' : 'strNbr'}=${storeNumber}`}
          name='daily'
          active={activeItem === 'daily'}
          content='Daily Sales'
          onClick={() => {
            setStoreNumber(storeNumberField.padStart(4, '0'))
            setLevel(levelSelection)
            setSubmitted(true)
            setActiveItem('daily')
          }}
        />
      </Menu>
      <Menu fluid attached widths={3} color='orange'>
        <Menu.Item
          as={Link} to={`/${level}/orderfulfillment?${level === 'store' ? 'strNbr' :
            level === 'district' ? 'dstNbr' : level === 'region' ? 'rgnNbr' : level === 'division' ? 'divNbr' : 'strNbr'}=${storeNumber}`}
          name='orderfulfillment'
          active={activeItem === 'orderfulfillment'}
          content='Order Fulfillment'
          onClick={() => {
            setStoreNumber(storeNumberField.padStart(4, '0'))
            setLevel(levelSelection)
            setSubmitted(true)
            setActiveItem('orderfulfillment')
          }}
        />
        <Menu.Item
          as={Link} to={`/${level}/credit?${level === 'store' ? 'strNbr' :
            level === 'district' ? 'dstNbr' : level === 'region' ? 'rgnNbr' : level === 'division' ? 'divNbr' : 'strNbr'}=${storeNumber}`}
          name='credit'
          active={activeItem === 'credit'}
          content='Credit'
          onClick={() => {
            setStoreNumber(storeNumberField.padStart(4, '0'))
            setLevel(levelSelection)
            setSubmitted(true)
            setActiveItem('credit')
          }}
        />
        <Menu.Item
          as={Link} to={`/${level}/specialtyprorental?${level === 'store' ? 'strNbr' :
            level === 'district' ? 'dstNbr' : level === 'region' ? 'rgnNbr' : level === 'division' ? 'divNbr' : 'strNbr'}=${storeNumber}`}
          name='specialtyprorental'
          active={activeItem === 'specialtyprorental'}
          content='Spec/Pro/Rental'
          onClick={() => {
            setStoreNumber(storeNumberField.padStart(4, '0'))
            setLevel(levelSelection)
            setSubmitted(true)
            setActiveItem('specialtyprorental')
          }}
        />
      </Menu>
      <Menu fluid attached='bottom' widths={3} color='orange'>
        <Menu.Item
          as={Link} to={`/${level}/shrink?${level === 'store' ? 'strNbr' :
            level === 'district' ? 'dstNbr' : level === 'region' ? 'rgnNbr' : level === 'division' ? 'divNbr' : 'strNbr'}=${storeNumber}`}
          name='shrink'
          active={activeItem.includes('shrink')}
          content='Shrink'
          onClick={() => {
            setStoreNumber(storeNumberField.padStart(4, '0'))
            setLevel(levelSelection)
            setSubmitted(true)
            setActiveItem('shrink')
          }}
        />
        <Menu.Item
          as={Link} to={`/${level}/events?${level === 'store' ? 'strNbr' :
            level === 'district' ? 'dstNbr' : level === 'region' ? 'rgnNbr' : level === 'division' ? 'divNbr' : 'strNbr'}=${storeNumber}`}
          name='events'
          active={activeItem.includes('events')}
          content='Events'
          onClick={() => {
            setStoreNumber(storeNumberField.padStart(4, '0'))
            setLevel(levelSelection)
            setSubmitted(true)
            setActiveItem('events')
          }}
        />
        <Menu.Item
          as={Link} to={`/${level}/headlinesinsights?${level === 'store' ? 'strNbr' :
            level === 'district' ? 'dstNbr' : level === 'region' ? 'rgnNbr' : level === 'division' ? 'divNbr' : 'strNbr'}=${storeNumber}`}
          name='headlinesinsights'
          active={activeItem === 'headlinesinsights'}
          content='Headlines & Insights'
          onClick={() => {
            setStoreNumber(storeNumberField.padStart(4, '0'))
            setLevel(levelSelection)
            setSubmitted(true)
            setActiveItem('headlinesinsights')
          }}
        />
      </Menu>
      <div>&nbsp;</div>
      <Grid >
        <Grid.Column width={12}>
          <Input
            label={<Dropdown
              defaultValue={level}
              value={levelSelection}
              options={
                [
                  { key: 'store', text: 'Store', value: 'store' },
                  { key: 'district', text: 'District', value: 'district' },
                  isMenuInDevelopment ? ({ key: 'region', text: 'Region', value: 'region' }) : <></>,
                  isMenuInDevelopment ? ({ key: 'division', text: 'Division', value: 'division' }) : <></>
                ]
              }
              onChange={(_, { value }) => {
                setSubmitted(false)
                setLevelSelection(value)
              }}
            />}
            labelPosition='left'
            style={{ fontSize: '16px' }}
            fluid
            value={storeNumberField}
            onChange={(_, { value }) => {
              if (!isNaN(value) && value.length < 5) {
                setSubmitted(false)
                setStoreNumberField(value)
              }
            }
            }
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                setStoreNumber(storeNumberField.padStart(4, '0'))
                setLevel(levelSelection)
                setActiveItem(['overview', 'intraday', 'daily', 'orderfulfillment', 'credit', 'specialtyprorental', 'shrink', 'events', 'headlinesinsights'].includes(active) ? active : 'overview')
                setSubmitted(true)
              }
            }}
          />
        </Grid.Column>
        <Grid.Column>
          {/* <Grid.Row>&nbsp;</Grid.Row> */}
          <Grid.Row>
            <Button size='large' icon='search' style={{ color: 'white', backgroundColor: '#EE7125' }} onClick={() => {
              setStoreNumber(storeNumberField.padStart(4, '0'))
              setLevel(levelSelection)
              setActiveItem(['overview', 'intraday', 'daily', 'orderfulfillment', 'credit', 'specialtyprorental', 'shrink', 'events', 'headlinesinsights'].includes(active) ? active : 'overview')
              setSubmitted(true)
            }} />
          </Grid.Row>
          <Grid.Row>&nbsp;</Grid.Row>
        </Grid.Column>
      </Grid>
      {/* <Scanner storeNumber={storeNumber} setStoreNumber={setStoreNumber} storeNumberField={storeNumberField} /> */}
      <ScannerV2 storeNumber={storeNumber} setStoreNumber={setStoreNumber} storeNumberField={storeNumberField} />
    </Segment>
  )
}