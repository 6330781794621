import { Link } from 'react-router-dom';
import { UnitMenu } from '../Common/UnitMenu';
import useQueryApi from '../../hooks/useQueryApi';
import React, { useState, useEffect } from 'react';
import { SubClassesTable } from './SubClassesTable';
import { timeframeCode } from '../Common/timeframeCode';
import { capitalizeFirstLetter } from '../../utils/string';
import { useQueryParams } from '../../hooks/useQueryParams';
import { TimeframeDropdown } from '../Common/TimeframeDropdown';
import { getPercentString, getDollarString, getCalloutStyle, getCompareStyle } from '../Common/TableCellUtils';
import { Header, Loader, Dropdown, Icon, Grid, Table, Menu, Pagination, Modal, Segment, Button } from 'semantic-ui-react';

export const ClassDrilldown = ({ timeframe, settimeframe, level, fpToggle }) => {
  const { strNbr = 0, dstNbr = '0001', rgnNbr = 0, classNbr = 0 } = useQueryParams();
  const orgNbr = strNbr || dstNbr || rgnNbr || 0;

  const [metric, setMetric] = useState('SLS');
  const [sortColumn, setSortColumn] = useState('MER_SUB_CLASS_NM');
  const [sortDirection, setSortDirection] = useState('ascending');
  const [unit, setUnit] = useState('NOM');
  const [sclsSku, setSclsSku] = useState('Subclasses');
  const [pageNum, setPageNum] = useState(1);
  const [rowsPerPage] = useState(20);

  const [open, setOpen] = useState(false);
  const [modalData, setModalData] = useState([]);

  const subClassesEndPointByLevel = {
    store: 'querySlsSclsByStrCls',
    district: 'querySlsSclsByDstCls',
    region: 'querySlsSclsByRgnCls'
  };
  const subClassesParamsByLevel = {
    store: { strNbr, classNbr, fpToggle },
    district: { dstNbr, classNbr, fpToggle },
    region: { locNbr: rgnNbr, classNbr, fpToggle }
  };

  const skuEndPointByLevel = {
    store: 'querySlsSkuByStrCls',
    district: 'querySlsSkuByDstCls',
    region: 'querySlsSkuByDstCls'
  };
  const skuParamsByLevel = {
    store: { strNbr, classNbr, fpToggle },
    district: { dstNbr, classNbr, fpToggle },
    region: { dstNbr, classNbr, fpToggle }
  };

  const {
    data: skuData,
    isError: skuIsError,
    isLoading: skuIsLoading
  } = useQueryApi(skuEndPointByLevel[level], skuParamsByLevel[level]);

  const {
    data: subClassesData,
    isError: subClassesIsError,
    isLoading: subClassesIsLoading
  } = useQueryApi(subClassesEndPointByLevel[level], subClassesParamsByLevel[level]);

  useEffect(() => {
    setPageNum(1);
  }, [timeframe, sortColumn, sortDirection, rowsPerPage]);

  if (subClassesIsError || skuIsError) return <p>Something went wrong.</p>;
  if (subClassesIsLoading || skuIsLoading) return <Loader active>Loading...</Loader>;

  let deptNbr = classNbr.toString().substring(1, 3);
  const deptNm = getDeptName(subClassesData, skuData, deptNbr);
  const classNm = getClassName(subClassesData, skuData, classNbr);

  const allSkus = skuData.filter(row => row && row['TRNSFM_TYP_SORT_ID'] === timeframeCode[timeframe]);
  const allSubclasses = subClassesData.filter(row => row && row['TRNSFM_TYP_SORT_ID'] === timeframeCode[timeframe]);

  let skuSorted = allSkus.sort((left, right) => {
    let result = left[sortColumn] - right[sortColumn];
    if (sortColumn === 'SKU_NBR') {
      result = left.SKU_NBR > right.SKU_NBR ? 1 : -1;
    }
    if (sortColumn === 'SKU_DESC') {
      result = left.SKU_DESC > right.SKU_DESC ? 1 : -1;
    }
    if (sortDirection === 'descending') {
      result *= -1;
    }
    return result;
  });

  let dataPagination = skuSorted.slice(
    rowsPerPage * (pageNum - 1),
    parseInt(Math.min(rowsPerPage * pageNum, skuSorted.length))
  );

  return (
    <>
      <Grid>
        <Grid.Column width={16} textAlign='left'>
          <Grid.Row>
            <a
              href={`/${level}/overview?${level === 'store' ? 'strNbr' : level === 'district' ? 'dstNbr' : 'strNbr'}=${orgNbr}`}
              style={{ color: '#EE7125', textDecoration: 'underline' }}>
              Overview
            </a>
            {' / '}
            <Link
              style={{ color: '#EE7125', textDecoration: 'underline' }}
              to={`/${level}/sales/dept?${level === 'store' ? 'strNbr' : level === 'district' ? 'dstNbr' : 'strNbr'}=${orgNbr}&deptNbr=${deptNbr}`}>
              {deptNm}
            </Link>
            {' / '}
            {classNm}
          </Grid.Row>
        </Grid.Column>
      </Grid>

      <Header textAlign='left' style={{ marginTop: '2.5rem', marginBottom: '2rem' }}>
        Overview - Sales - Classes - {capitalizeFirstLetter(level)} {strNbr || dstNbr || rgnNbr}, {allSubclasses[0]?.RGN_NM}
        <Header.Subheader>Data as of {allSubclasses[0]?.TRNSFM_DT}</Header.Subheader>
      </Header>

      <Grid>
        <Grid.Row>
          <Grid.Column textAlign='justified' width={8}>
            <Grid.Row>Metric:</Grid.Row>
            <Grid.Row>
              <Dropdown
                defaultValue={metric}
                selection
                scrolling
                compact
                size='small'
                options={[
                  { key: 'SLS', text: 'Sales', value: 'SLS' },
                  { key: 'UNT', text: 'Units', value: 'UNT' },
                  { key: 'TXN', text: 'Trans', value: 'TXN' },
                  { key: 'AVG_TCKT', text: 'Avg Ticket', value: 'AVG_TCKT' }
                ]}
                onChange={(_, { value }) => {
                  setMetric(value);
                }}
              />
            </Grid.Row>
          </Grid.Column>

          <Grid.Column width={8}>
            <Grid.Row>Timeframe:</Grid.Row>
            <Grid.Row>
              <TimeframeDropdown timeframe={timeframe} settimeframe={settimeframe} />
            </Grid.Row>
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <Grid>
        <Grid.Row>
          <Grid.Column width={16} textAlign='center'>
            <Menu fluid widths={2} color='orange'>
              <Menu.Item
                name='Subclasses'
                active={sclsSku === 'Subclasses'}
                content='Subclasses'
                onClick={() => {
                  setSclsSku('Subclasses');
                }}
              />
              <Menu.Item
                name='SKUs'
                active={sclsSku === 'SKUs'}
                content='SKUs'
                onClick={() => {
                  setSclsSku('SKUs');
                }}
              />
            </Menu>
          </Grid.Column>
        </Grid.Row>
      </Grid>

      {sclsSku === 'Subclasses' && (
        <Grid>
          <Grid.Row>&nbsp;</Grid.Row>
        </Grid>
      )}

      {sclsSku === 'Subclasses' && (
        <SubClassesTable subClassesList={allSubclasses} level={level} metric={metric} orgNbr={orgNbr} />
      )}

      {sclsSku === 'SKUs' && allSkus.length > rowsPerPage && (
        <Grid>
          <Grid.Row>
            <Grid.Column width={8} textAlign='justified'>
              <Grid.Row>Units:</Grid.Row>
              <UnitMenu unit={unit} setUnit={setUnit} />
            </Grid.Column>
            <Grid.Column width={8} />
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={16} textAlign='center'>
              <Pagination
                size='mini'
                defaultActivePage={1}
                totalPages={Math.floor((allSkus.length - 1) / rowsPerPage) + 1}
                activePage={pageNum}
                boundaryRange={0}
                siblingRange={1}
                onPageChange={(e, { activePage }) => {
                  setPageNum(activePage);
                }}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      )}

      {sclsSku === 'SKUs' && (
        <Table textAlign='center' unstackable celled sortable size='small' style={{ fontSize: 12 }}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell
                sorted={sortColumn === 'SKU_NBR' ? sortDirection : null}
                style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                onClick={() => {
                  if (sortColumn !== 'SKU_NBR') {
                    setSortColumn('SKU_NBR');
                    setSortDirection('ascending');
                  } else {
                    sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending');
                  }
                }}>
                SKU {sortColumn !== 'SKU_NBR' && <Icon fitted name='sort' />}
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={sortColumn === 'SKU_DESC' ? sortDirection : null}
                style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                onClick={() => {
                  if (sortColumn !== 'SKU_DESC') {
                    setSortColumn('SKU_DESC');
                    setSortDirection('ascending');
                  } else {
                    sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending');
                  }
                }}>
                SKU Description {sortColumn !== 'SKU_DESC' && <Icon fitted name='sort' />}
              </Table.HeaderCell>
              {metric === 'SLS' && (
                <Table.HeaderCell
                  sorted={sortColumn === 'TY_SLS_AMT' ? sortDirection : null}
                  style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                  onClick={() => {
                    if (sortColumn !== 'TY_SLS_AMT') {
                      setSortColumn('TY_SLS_AMT');
                      setSortDirection('ascending');
                    } else {
                      sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending');
                    }
                  }}>
                  Sales Amt ($) {sortColumn !== 'TY_SLS_AMT' && <Icon fitted name='sort' />}
                </Table.HeaderCell>
              )}
              {metric === 'SLS' && unit === 'NOM' && (
                <Table.HeaderCell
                  sorted={sortColumn === 'SLS_COMP_NOM' ? sortDirection : null}
                  style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                  onClick={() => {
                    if (sortColumn !== 'SLS_COMP_NOM') {
                      setSortColumn('SLS_COMP_NOM');
                      setSortDirection('ascending');
                    } else {
                      sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending');
                    }
                  }}>
                  Sales vLY ($) {sortColumn !== 'SLS_COMP_NOM' && <Icon fitted name='sort' />}
                </Table.HeaderCell>
              )}
              {metric === 'SLS' && unit === 'PCT' && (
                <Table.HeaderCell
                  sorted={sortColumn === 'SLS_COMP_PCT' ? sortDirection : null}
                  style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                  onClick={() => {
                    if (sortColumn !== 'SLS_COMP_PCT') {
                      setSortColumn('SLS_COMP_PCT');
                      setSortDirection('ascending');
                    } else {
                      sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending');
                    }
                  }}>
                  Sales vLY (%) {sortColumn !== 'SLS_COMP_PCT' && <Icon fitted name='sort' />}
                </Table.HeaderCell>
              )}
              {metric === 'UNT' && (
                <Table.HeaderCell
                  sorted={sortColumn === 'TY_UNT_SLS' ? sortDirection : null}
                  style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                  onClick={() => {
                    if (sortColumn !== 'TY_UNT_SLS') {
                      setSortColumn('TY_UNT_SLS');
                      setSortDirection('ascending');
                    } else {
                      sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending');
                    }
                  }}>
                  Unit Sales (#) {sortColumn !== 'TY_UNT_SLS' && <Icon fitted name='sort' />}
                </Table.HeaderCell>
              )}
              {metric === 'UNT' && unit === 'NOM' && (
                <Table.HeaderCell
                  sorted={sortColumn === 'UNT_COMP_NOM' ? sortDirection : null}
                  style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                  onClick={() => {
                    if (sortColumn !== 'UNT_COMP_NOM') {
                      setSortColumn('UNT_COMP_NOM');
                      setSortDirection('ascending');
                    } else {
                      sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending');
                    }
                  }}>
                  Units vLY (#) {sortColumn !== 'UNT_COMP_NOM' && <Icon fitted name='sort' />}
                </Table.HeaderCell>
              )}
              {metric === 'UNT' && unit === 'PCT' && (
                <Table.HeaderCell
                  sorted={sortColumn === 'UNT_COMP_PCT' ? sortDirection : null}
                  style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                  onClick={() => {
                    if (sortColumn !== 'UNT_COMP_PCT') {
                      setSortColumn('UNT_COMP_PCT');
                      setSortDirection('ascending');
                    } else {
                      sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending');
                    }
                  }}>
                  Units vLY (%) {sortColumn !== 'UNT_COMP_PCT' && <Icon fitted name='sort' />}
                </Table.HeaderCell>
              )}
              {metric === 'TXN' && (
                <Table.HeaderCell
                  sorted={sortColumn === 'TY_TXN_CNT' ? sortDirection : null}
                  style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                  onClick={() => {
                    if (sortColumn !== 'TY_TXN_CNT') {
                      setSortColumn('TY_TXN_CNT');
                      setSortDirection('ascending');
                    } else {
                      sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending');
                    }
                  }}>
                  Trans Cnt (#) {sortColumn !== 'TY_TXN_CNT' && <Icon fitted name='sort' />}
                </Table.HeaderCell>
              )}
              {metric === 'TXN' && unit === 'NOM' && (
                <Table.HeaderCell
                  sorted={sortColumn === 'TXN_COMP_NOM' ? sortDirection : null}
                  style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                  onClick={() => {
                    if (sortColumn !== 'TXN_COMP_NOM') {
                      setSortColumn('TXN_COMP_NOM');
                      setSortDirection('ascending');
                    } else {
                      sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending');
                    }
                  }}>
                  Trans vLY (#) {sortColumn !== 'TXN_COMP_NOM' && <Icon fitted name='sort' />}
                </Table.HeaderCell>
              )}
              {metric === 'TXN' && unit === 'PCT' && (
                <Table.HeaderCell
                  sorted={sortColumn === 'TXN_COMP_PCT' ? sortDirection : null}
                  style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                  onClick={() => {
                    if (sortColumn !== 'TXN_COMP_PCT') {
                      setSortColumn('TXN_COMP_PCT');
                      setSortDirection('ascending');
                    } else {
                      sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending');
                    }
                  }}>
                  Trans vLY (%) {sortColumn !== 'TXN_COMP_PCT' && <Icon fitted name='sort' />}
                </Table.HeaderCell>
              )}
              {metric === 'AVG_TCKT' && (
                <Table.HeaderCell
                  sorted={sortColumn === 'TY_AVG_TCKT' ? sortDirection : null}
                  style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                  onClick={() => {
                    if (sortColumn !== 'TY_AVG_TCKT') {
                      setSortColumn('TY_AVG_TCKT');
                      setSortDirection('ascending');
                    } else {
                      sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending');
                    }
                  }}>
                  Avg Ticket ($) {sortColumn !== 'TY_AVG_TCKT' && <Icon fitted name='sort' />}
                </Table.HeaderCell>
              )}
              {metric === 'AVG_TCKT' && unit === 'NOM' && (
                <Table.HeaderCell
                  sorted={sortColumn === 'AVG_TCKT_COMP_NOM' ? sortDirection : null}
                  style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                  onClick={() => {
                    if (sortColumn !== 'AVG_TCKT_COMP_NOM') {
                      setSortColumn('AVG_TCKT_COMP_NOM');
                      setSortDirection('ascending');
                    } else {
                      sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending');
                    }
                  }}>
                  Avg Ticket vLY ($) {sortColumn !== 'AVG_TCKT_COMP_NOM' && <Icon fitted name='sort' />}
                </Table.HeaderCell>
              )}
              {metric === 'AVG_TCKT' && unit === 'PCT' && (
                <Table.HeaderCell
                  sorted={sortColumn === 'AVG_TCKT_COMP_PCT' ? sortDirection : null}
                  style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                  onClick={() => {
                    if (sortColumn !== 'AVG_TCKT_COMP_PCT') {
                      setSortColumn('AVG_TCKT_COMP_PCT');
                      setSortDirection('ascending');
                    } else {
                      sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending');
                    }
                  }}>
                  Avg Ticket vLY (%) {sortColumn !== 'AVG_TCKT_COMP_PCT' && <Icon fitted name='sort' />}
                </Table.HeaderCell>
              )}
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {dataPagination.map(data => {
              return (
                <Table.Row>
                  <Table.Cell
                    style={{ color: '#EE7125', textDecoration: 'underline' }}
                    singleLine
                    onClick={() => {
                      setOpen(true);
                      setModalData(data);
                    }}>
                    {data['SKU_NBR'].toString().length > 6 // Format SKUs, 10-digit (xxxx-xxx-xxx) versus 6-digit (xxx-xxx)
                      ? data['SKU_NBR'].toString().substring(0, 4) +
                        '-' +
                        data['SKU_NBR'].toString().substring(4, 7) +
                        '-' +
                        data['SKU_NBR'].toString().substring(7)
                      : data['SKU_NBR'].toString().substring(0, 3) + '-' + data['SKU_NBR'].toString().substring(3)}
                  </Table.Cell>
                  <Table.Cell>{data['SKU_DESC']}</Table.Cell>
                  {/* Sales metrics */}
                  {metric === 'SLS' && (
                    <Table.Cell singleLine style={getCalloutStyle(data['TY_SLS_AMT'])}>
                      {getDollarString(data['TY_SLS_AMT'])}
                    </Table.Cell>
                  )}
                  {metric === 'SLS' && unit === 'NOM' && (
                    <Table.Cell singleLine style={getCompareStyle(data['SLS_COMP_NOM'])}>
                      {getDollarString(data['SLS_COMP_NOM'])}
                    </Table.Cell>
                  )}
                  {metric === 'SLS' && unit === 'PCT' && (
                    <Table.Cell singleLine style={getCompareStyle(data['SLS_COMP_PCT'])}>
                      {getPercentString(data['SLS_COMP_PCT'])}
                    </Table.Cell>
                  )}
                  {/* Units metrics */}
                  {metric === 'UNT' && (
                    <Table.Cell singleLine style={getCalloutStyle(data['TY_UNT_SLS'])}>
                      {data['TY_UNT_SLS'] != null
                        ? data['TY_UNT_SLS'].toLocaleString(undefined, {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0
                          })
                        : 'Loading'}
                    </Table.Cell>
                  )}
                  {metric === 'UNT' && unit === 'NOM' && (
                    <Table.Cell singleLine style={getCompareStyle(data['UNT_COMP_NOM'])}>
                      {data['UNT_COMP_NOM'] != null
                        ? data['UNT_COMP_NOM'].toLocaleString(undefined, {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0
                          })
                        : 'Loading'}
                    </Table.Cell>
                  )}
                  {metric === 'UNT' && unit === 'PCT' && (
                    <Table.Cell singleLine style={getCompareStyle(data['UNT_COMP_PCT'])}>
                      {getPercentString(data['UNT_COMP_PCT'])}
                    </Table.Cell>
                  )}
                  {/* Transactions metrics */}
                  {metric === 'TXN' && (
                    <Table.Cell singleLine style={getCalloutStyle(data['TY_TXN_CNT'])}>
                      {data['TY_TXN_CNT'] != null
                        ? data['TY_TXN_CNT'].toLocaleString(undefined, {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0
                          })
                        : 'Loading'}
                    </Table.Cell>
                  )}
                  {metric === 'TXN' && unit === 'NOM' && (
                    <Table.Cell singleLine style={getCompareStyle(data['TXN_COMP_NOM'])}>
                      {data['TXN_COMP_NOM'] != null
                        ? data['TXN_COMP_NOM'].toLocaleString(undefined, {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0
                          })
                        : 'Loading'}
                    </Table.Cell>
                  )}
                  {metric === 'TXN' && unit === 'PCT' && (
                    <Table.Cell singleLine style={getCompareStyle(data['TXN_COMP_PCT'])}>
                      {getPercentString(data['TXN_COMP_PCT'])}
                    </Table.Cell>
                  )}
                  {/* Average Ticket Metrics */}
                  {metric === 'AVG_TCKT' && (
                    <Table.Cell singleLine style={getCalloutStyle(data['TY_AVG_TCKT'])}>
                      {getDollarString(data['TY_AVG_TCKT'])}
                    </Table.Cell>
                  )}
                  {metric === 'AVG_TCKT' && unit === 'NOM' && (
                    <Table.Cell singleLine style={getCompareStyle(data['AVG_TCKT_COMP_NOM'])}>
                      {getDollarString(data['AVG_TCKT_COMP_NOM'])}
                    </Table.Cell>
                  )}
                  {metric === 'AVG_TCKT' && unit === 'PCT' && (
                    <Table.Cell singleLine style={getCompareStyle(data['AVG_TCKT_COMP_PCT'])}>
                      {getPercentString(data['AVG_TCKT_COMP_PCT'])}
                    </Table.Cell>
                  )}
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      )}

      {open && (
        <Modal
          closeIcon
          open={open}
          onClose={() => {
            setOpen(false);
          }}>
          <Segment color='orange'>
            <Grid>
              <Grid.Column width={16}>
                <Grid.Row>
                  <Header textAlign='center'>
                    {modalData['SKU_NBR'].toString().length > 6 // Format SKUs, 10-digit (xxxx-xxx-xxx) versus 6-digit (xxx-xxx)
                      ? modalData['SKU_NBR'].toString().substring(0, 4) +
                        '-' +
                        modalData['SKU_NBR'].toString().substring(4, 7) +
                        '-' +
                        modalData['SKU_NBR'].toString().substring(7)
                      : modalData['SKU_NBR'].toString().substring(0, 3) + '-' + modalData['SKU_NBR'].toString().substring(3)}
                  </Header>
                </Grid.Row>
                <Grid.Row>&nbsp;</Grid.Row>
                <Grid.Row>
                  <Header>Class</Header>
                </Grid.Row>
                <Grid.Row>
                  <p textAlign='center'>{modalData['EXT_CLASS_NBR'] + '-' + modalData['CLASS_DESC']}</p>
                </Grid.Row>
                <Grid.Row>&nbsp;</Grid.Row>
                <Grid.Row>
                  <Header>Subclass</Header>
                </Grid.Row>
                <Grid.Row>
                  <p textAlign='center'>{modalData['EXT_SUB_CLASS_NBR'] + '-' + modalData['SUB_CLASS_DESC']}</p>
                </Grid.Row>
                <Grid.Row>&nbsp;</Grid.Row>
                <Grid.Row>
                  <Header>SKU Description</Header>
                </Grid.Row>
                <Grid.Row>
                  <p textAlign='center'>{modalData['SKU_DESC']}</p>
                </Grid.Row>
              </Grid.Column>
              &nbsp; &nbsp;
              <Grid.Column>
                <Grid.Row>&nbsp;</Grid.Row>
                <Grid.Row>
                  <Button
                    onClick={() => {
                      setOpen(false);
                    }}
                    style={{ color: 'white', backgroundColor: '#EE7125' }}
                    content='Close'
                  />
                </Grid.Row>
                <Grid.Row>&nbsp;</Grid.Row>
              </Grid.Column>
            </Grid>
          </Segment>
        </Modal>
      )}
    </>
  );
};

const getDeptName = (subClassesData, skuData, deptNbr) => {
  if (subClassesData.length > 0) return subClassesData[0]['MER_DEPT_NM'];
  if (skuData.length > 0) return `${skuData[0]['DEPT_NBR']}-${skuData[0]['DEPT_NM']}`;
  return deptNbr;
};

const getClassName = (subClassesData, skuData, classNbr) => {
  if (subClassesData.length > 0) return subClassesData[0]['MER_CLASS_NM'];
  if (skuData.length > 0) return `${skuData[0]['EXT_CLASS_NBR']}-${skuData[0]['CLASS_DESC']}`;
  return classNbr;
};
