import { DataTable } from '../Common/DataTable';
import { getParamByLevel } from '../../utils/timeFrameUtils';

export const SubClassesTable = ({ subClassesList, level, metric, orgNbr }) => {
  const param = getParamByLevel(level);

  const dataObj = {
    headers: headersByMetric[metric],
    data: subClassesList.map(data => [
      {
        stringValue: data['MER_SUB_CLASS_NM'],
        link: {
          to: `/${level}/sales/subclass`,
          params: {
            [param]: orgNbr,
            subClassNbr: data['EXT_SUB_CLASS_NBR']
          }
        }
      },
      ...getColumnsByMetric(data, metric)
    ]),
    sortable: true,
    sortableDefault: 0
  };

  return <DataTable data={dataObj} />;
};

const headersByMetric = {
  SLS: [{ name: 'Subclass ' }, { name: 'Sales Amt ($) ' }, { name: 'Sales vLY ($) ' }, { name: 'Sales vLY (%) ' }],
  UNT: [{ name: 'Subclass ' }, { name: 'Unit Sales (#) ' }, { name: 'Units vLY (#) ' }, { name: 'Units vLY (%) ' }],
  TXN: [{ name: 'Subclass ' }, { name: 'Trans Cnt (#) ' }, { name: 'Trans vLY (#) ' }, { name: 'Trans vLY (%) ' }],
  AVG_TCKT: [
    { name: 'Subclass ' },
    { name: 'Avg Ticket ($) ' },
    { name: 'Avg Ticket vLY ($) ' },
    { name: 'Avg Ticket vLY (%) ' }
  ]
};

function getColumnsByMetric(data, metric) {
  switch (metric) {
    case 'SLS':
      return [
        {
          decimals: 2,
          dollarValue: data['TY_SLS_AMT'],
          customStyle: { color: data['TY_SLS_AMT'] >= 0 ? 'black' : 'red' }
        },
        {
          decimals: 2,
          compareStyle: true,
          dollarValue: data['SLS_COMP_NOM']
        },
        {
          decimals: 2,
          compareStyle: true,
          pctValue: data['SLS_COMP_PCT']
        }
      ];
    case 'UNT':
      return [
        {
          stringValue: data['TY_UNT_SLS'].toLocaleString(),
          customStyle: { color: data['TY_UNT_SLS'] >= 0 ? 'black' : 'red' }
        },
        {
          compareStyle: true,
          stringValue: data['UNT_COMP_NOM'].toLocaleString()
        },
        {
          decimals: 2,
          compareStyle: true,
          pctValue: data['UNT_COMP_PCT']
        }
      ];
    case 'TXN':
      return [
        {
          stringValue: data['TY_TXN_CNT'].toLocaleString(),
          customStyle: { color: data['TY_TXN_CNT'] >= 0 ? 'black' : 'red' }
        },
        {
          compareStyle: true,
          stringValue: data['TXN_COMP_NOM'].toLocaleString()
        },
        {
          decimals: 2,
          compareStyle: true,
          pctValue: data['TXN_COMP_PCT']
        }
      ];
    case 'AVG_TCKT':
      return [
        {
          decimals: 2,
          dollarValue: data['TY_AVG_TCKT'],
          customStyle: { color: data['TY_AVG_TCKT'] >= 0 ? 'black' : 'red' }
        },
        { decimals: 2, compareStyle: true, dollarValue: data['AVG_TCKT_COMP_NOM'] },
        { decimals: 2, compareStyle: true, pctValue: data['AVG_TCKT_COMP_PCT'] }
      ];
    default:
      return [];
  }
}
