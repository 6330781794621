import React from 'react'
import {
  Tab,
  Grid,
} from 'semantic-ui-react'
import { withRouter } from 'react-router-dom'
import { ClassesByStoreTab } from '../ProdHierDrilldown/ClassesByStoreTab'
import { SubclassesByStoreTab } from '../ProdHierDrilldown/SubclassesByStoreTab'
import { DeptsByStoreTab } from '../ProdHierDrilldown/DeptsByStoreTab'
import { Top40Btm40Skus } from '../ProdHierDrilldown/Top40Btm40Skus'

export const SalesTab = withRouter(({ dataSlsDeptByStr, dataSlsClsByStr, dataSlsSclsByStr, Top40Btm40Query, timeframe, location, strNm }) => {

  const search = new URLSearchParams(location.search)
  const strNbr = search.has('strNbr') ? search.get('strNbr') : 0

  const deptsByStore = <DeptsByStoreTab
    strNbr={strNbr}
    data={dataSlsDeptByStr}
    timeframe={timeframe}
    strNm={strNm}
  />

  const classesByStore = <ClassesByStoreTab
    strNbr={strNbr}
    data={dataSlsClsByStr}
    timeframe={timeframe}
    strNm={strNm}
  />
  const subclassesByStore = <SubclassesByStoreTab
    strNbr={strNbr}
    data={dataSlsSclsByStr}
    timeframe={timeframe}
    strNm={strNm}
  />
  const skusByStore = <Top40Btm40Skus
  timeframe={timeframe}
  data={Top40Btm40Query?.data}
  strNm={strNm}

/>

  const tabs = [
    {
      menuItem: 'Dept',
      render: () => deptsByStore
    },

    {
      menuItem: 'Classes',
      render: () => classesByStore
    },

    {
      menuItem: 'Subclasses',
      render: () => subclassesByStore
    },
    {
      menuItem: 'Skus',
      render: () => skusByStore
    }
  ]

  return (
    <Tab.Pane>
      <Grid>
        <Grid.Column>
          <Grid.Row>
            <Tab menu={{ attached: false, pointing: false, secondary: false, borderless: false, compact: true, size: 'small', widths: 4 }} panes={tabs} />
          </Grid.Row>
        </Grid.Column>
      </Grid>
    </Tab.Pane>
  )
}
)