import { useState } from "react"
import { VOC_LTPA_GOAL, VOC_LTSA_GOAL } from "../../../constants/goals"
import { DataView } from "../../Common/DataView"
import { getCompareStyle, getDollarString, getPercentString } from "../../Common/TableCellUtils"
import { Grid, Header } from "semantic-ui-react"
const headersList = (metrict, unit) => {
  if (metrict === 'Days Safe') {
    return [
      { name: 'Division # ' },
      { name: 'Days Safe ' }
    ]
  }
  return [
    { name: `Division # ` },
    { name: `Actual ` },
    { name: `vP/vF  ${unit === 'PCT' ? '% ' : ''}` },
    { name: `vLY  ${unit === 'PCT' ? '% ' : ''}` },
  ]
}

const bodyRows = ({ timeframe, metric, row, unit }) => {
  const listOfRecords = [{
    stringValue: `DST ${row['DST_NBR']}`,
    orderBy: parseInt(row['DST_NBR']),
    link: {
      to: '/district/overview',
      params: {
        'dstNbr': row['DST_NBR']
      }
    }
  }]
  if (metric === 'Days Safe') {
    listOfRecords.push({
      stringValue: row['DAYS_SAFE']
    })
  }
  if (metric === 'Sales') {
    const currentList = [{
      stringValue: getDollarString(row[timeframe + propsByMetric[metric]['actual']], 1),
    },
    {
      stringValue: unit !== 'PCT'
        ? getDollarString(row[timeframe + propsByMetric[metric][unit + '_VP']], 1)
        : getPercentString(row[timeframe + propsByMetric[metric][unit + '_VP']], 3),
      customStyle: getCompareStyle(row[timeframe + propsByMetric[metric][unit + '_VP']])
    },
    {
      stringValue: unit !== 'PCT'
        ? getDollarString(row[timeframe + propsByMetric[metric][unit + '_VLY']], 1)
        : getPercentString(row[timeframe + propsByMetric[metric][unit + '_VLY']], 3),
      customStyle: getCompareStyle(row[timeframe + propsByMetric[metric][unit + '_VLY']])
    }]
    listOfRecords.push(...currentList)
  }
  if (metric === 'Units') {
    const currentList = [{
      stringValue: (+row[timeframe + propsByMetric[metric]['actual']]).toLocaleString(undefined, { maximumFractionDigits: 0 }),
    },
    {
      stringValue: '-',
    },
    {
      stringValue: unit !== 'PCT'
        ? (+row[timeframe + propsByMetric[metric][unit + '_VLY']]).toLocaleString(undefined, { maximumFractionDigits: 0 })
        : getPercentString(row[timeframe + propsByMetric[metric][unit + '_VLY']]),
      customStyle: getCompareStyle(row[timeframe + propsByMetric[metric][unit + '_VLY']])
    }]
    listOfRecords.push(...currentList)
  }
  if (metric === 'Transactions') {
    const currentList = [{
      stringValue: (+row[timeframe + propsByMetric[metric]['actual']]).toLocaleString(undefined, { maximumFractionDigits: 0 }),
    },
    {
      stringValue: '-',
    },
    {
      stringValue: unit !== 'PCT'
        ? (+row[timeframe + propsByMetric[metric][unit + '_VLY']]).toLocaleString(undefined, { maximumFractionDigits: 0 })
        : getPercentString(row[timeframe + propsByMetric[metric][unit + '_VLY']]),
      customStyle: getCompareStyle(row[timeframe + propsByMetric[metric][unit + '_VLY']])
    }]
    listOfRecords.push(...currentList)
  }
  if (metric === 'Average Ticket') {
    const currentList = [{
      stringValue: getDollarString(row[timeframe + propsByMetric[metric]['actual']], 2),
    },
    {
      stringValue: '-',
    },
    {
      stringValue: unit !== 'PCT'
        ? getDollarString(row[timeframe + propsByMetric[metric][unit + '_VLY']], 2)
        : getPercentString(row[timeframe + propsByMetric[metric][unit + '_VLY']], 3),
      customStyle: getCompareStyle(row[timeframe + propsByMetric[metric][unit + '_VLY']])
    }]
    listOfRecords.push(...currentList)
  }
  if (metric === 'Units per Basket') {
    const unitsPerBasketByNOM = (row['TY_' + timeframe + '_COMP_UNT_SLS'] / row['TY_' + timeframe + '_COMP_TXN_CNT'] -
      row['LY_' + timeframe + '_COMP_UNT_SLS'] / row['LY_' + timeframe + '_COMP_TXN_CNT'])
    const unitsPerBasketByPCT = (row['TY_' + timeframe + '_COMP_UNT_SLS'] / row['TY_' + timeframe + '_COMP_TXN_CNT'] -
      row['LY_' + timeframe + '_COMP_UNT_SLS'] / row['LY_' + timeframe + '_COMP_TXN_CNT']) /
      (row['LY_' + timeframe + '_COMP_UNT_SLS'] / row['LY_' + timeframe + '_COMP_TXN_CNT'])
    const currentList = [{
      stringValue: ((row['TY_' + timeframe + '_UNT_SLS']) / (row['TY_' + timeframe + '_TXN_CNT'])).toLocaleString(
        undefined,
        { maximumFractionDigits: 2 }
      ),
    },
    {
      stringValue: '-',
    },
    {
      stringValue: unit !== 'PCT'
        ? unitsPerBasketByNOM.toLocaleString(undefined, { maximumFractionDigits: 2 })
        : getPercentString(unitsPerBasketByPCT, 2),
      customStyle: getCompareStyle(unitsPerBasketByNOM)
    }]
    listOfRecords.push(...currentList)
  }
  if (metric === 'SMD') {
    const currentList = [{
      stringValue: getPercentString(row[timeframe + '_SMD_ACT'], 2)
    },
    {
      stringValue: unit !== 'PCT'
        ? (+row[timeframe + '_SMD_VP']).toLocaleString(undefined, { maximumFractionDigits: 0 }) + ' bps'
        : '-',
      customStyle: getCompareStyle(-1 * row[timeframe + '_SMD_VP'])
    },
    {
      stringValue: unit !== 'PCT'
        ? (+row[timeframe + '_SMD_VLY']).toLocaleString(undefined, { maximumFractionDigits: 0 }) + ' bps'
        : '-',
      customStyle: getCompareStyle(-1 * row[timeframe + '_SMD_VLY'])
    }]
    listOfRecords.push(...currentList)
  }
  if (metric === 'Labor') {
    const currentList = [{
      stringValue: (+row[timeframe + '_LBR_ACT']).toLocaleString(undefined, { maximumFractionDigits: 0 }) + ' hrs'
    },
    {
      stringValue: unit === 'NOM'
        ? (+row[timeframe + propsByMetric[metric][unit + '_VP']]).toLocaleString(undefined, { maximumFractionDigits: 0 }) + ' hrs'
        : getPercentString(row[timeframe + propsByMetric[metric][unit + '_VP']]),
      customStyle: unit === 'PCT' ? getCompareStyle(row[timeframe + propsByMetric[metric][unit + '_VP']]) : undefined
    },
    {
      stringValue: '-'
    }]
    listOfRecords.push(...currentList)
  }
  if (metric === 'GET') {
    const currentList = [{
      stringValue: getPercentString(row[timeframe + propsByMetric[metric]['actual']], 2),
      customStyle: {
        color: (+row[timeframe + propsByMetric[metric]['actual']]) >= VOC_LTSA_GOAL ? 'green' : 'red'
      }
    },
    {
      stringValue: '-'
    },
    {
      stringValue: unit === 'NOM' ? getPercentString(row[timeframe + propsByMetric[metric][unit + '_VLY']], 2) : '-',
      customStyle: {
        color: (+row[timeframe + propsByMetric[metric][unit + '_VLY']]) >= VOC_LTSA_GOAL ? 'green' : 'red'
      }
    }]
    listOfRecords.push(...currentList)
  }
  if (metric === 'LTSA') {
    const currentList = [{
      stringValue: getPercentString(row[timeframe + propsByMetric[metric]['actual']], 2),
      customStyle: {
        color: (+row[timeframe + propsByMetric[metric]['actual']]) >= VOC_LTSA_GOAL ? 'green' : 'red'
      }
    },
    {
      stringValue: '-'
    },
    {
      stringValue: unit === 'NOM' ? getPercentString(row[timeframe + propsByMetric[metric][unit + '_VLY']], 2) : '-',
      customStyle: {
        color: (+row[timeframe + propsByMetric[metric][unit + '_VLY']]) >= VOC_LTSA_GOAL ? 'green' : 'red'
      }
    }]
    listOfRecords.push(...currentList)
  }
  if (metric === 'LTPA') {
    const currentList = [{
      stringValue: getPercentString(row[timeframe + propsByMetric[metric]['actual']], 2),
      customStyle: {
        color:
          (+row[timeframe + '_ONLINE_SAT_ACT']) > VOC_LTPA_GOAL
            ? 'green'
            : (+row[timeframe + '_ONLINE_SAT_ACT']) < VOC_LTPA_GOAL
              ? 'red'
              : 'black'
      }
    },
    {
      stringValue: '-'
    },
    {
      stringValue: unit === 'NOM' ? getPercentString(row[timeframe + propsByMetric[metric][unit + '_VLY']], 2) : '-',
      customStyle: getCompareStyle(row[timeframe + propsByMetric[metric][unit + '_VLY']])
    }]
    listOfRecords.push(...currentList)
  }
  if (metric === 'Leads') {
    const currentList = [{
      stringValue: (+row[timeframe + propsByMetric[metric]['actual']]).toLocaleString(undefined, { maximumFractionDigits: 0 })
    },
    {
      stringValue: '-'
    },
    {
      stringValue: unit !== 'NOM'
        ? getPercentString(row[timeframe + propsByMetric[metric][unit + '_VLY']])
        : (+row[timeframe + propsByMetric[metric][unit + '_VLY']]).toLocaleString(undefined, { maximumFractionDigits: 0 }),
      customStyle: getCompareStyle(row[timeframe + propsByMetric[metric][unit + '_VLY']])
    }]
    listOfRecords.push(...currentList)
  }
  if (metric === 'Measures') {
    const currentList = [{
      stringValue: (+row[timeframe + propsByMetric[metric]['actual']]).toLocaleString(undefined, { maximumFractionDigits: 0 })
    },
    {
      stringValue: '-'
    },
    {
      stringValue: unit !== 'NOM'
        ? getPercentString(row[timeframe + propsByMetric[metric][unit + '_VLY']])
        : (+row[timeframe + propsByMetric[metric][unit + '_VLY']]).toLocaleString(undefined, { maximumFractionDigits: 0 }),
      customStyle: getCompareStyle(row[timeframe + propsByMetric[metric][unit + '_VLY']])
    }]
    listOfRecords.push(...currentList)
  }
  if (metric === 'HDPP - MA UA') {
    const currentList = [{
      stringValue: getPercentString(row[timeframe + '_MA_HDPP'])
    },
    {
      stringValue: getPercentString(row[timeframe + '_MA_HDPP_VG']),
      customStyle: getCompareStyle(row[timeframe + '_MA_HDPP_VG'])
    },
    {
      stringValue: getPercentString(row[timeframe + '_MA_HDPP_VLY']),
      customStyle: getCompareStyle(row[timeframe + '_MA_HDPP_VLY'])
    }]
    listOfRecords.push(...currentList)
  }
  if (metric === 'HDPP - GM UA') {
    const currentList = [{
      stringValue: getPercentString(row[timeframe + '_MA_HDPP'])
    },
    {
      stringValue: getPercentString(row[timeframe + '_GM_HDPP_VG']),
      customStyle: getCompareStyle(row[timeframe + '_GM_HDPP_VG'])
    },
    {
      stringValue: getPercentString(row[timeframe + '_GM_HDPP_VLY']),
      customStyle: getCompareStyle(row[timeframe + '_GM_HDPP_VLY'])
    }]
    listOfRecords.push(...currentList)
  }

  return listOfRecords
}

export const RegionSalesByDistrictList = ({ timeframe, metricsByDistrict, unit, rgnNbr, SafetyIncOshaLtGlbiByRgnData, dataAsOf, setMetric, metric }) => {
  
  const listByDaysSafe = SafetyIncOshaLtGlbiByRgnData.filter(row => row.METRIC_LEVEL === 'DST').map(row => bodyRows({ timeframe, row, metric, unit }));

  const districtListDataObj = {
    headers: headersList(metric, unit),
    data: metric === 'Days Safe' ? listByDaysSafe : metricsByDistrict.map(row => bodyRows({ timeframe, row, metric, unit })),
    sortable: true,
    sortableDefault: 0
  }

  return (
    <>
      <Grid>
        <Grid.Column width={8} />
        <Grid.Column width={8}>
          Metric:
          <div>
            <select
              name='metric'
              onChange={e => {
                setMetric(e.target.value);
              }}
              value={metric}
              style={{ padding: 10 }}>
              <option value='Sales' selected>
                Sales
              </option>
              <option value='Units'>Units</option>
              <option value='Transactions'>Transactions</option>
              <option value='Average Ticket'>Average Ticket</option>
              <option value='Units per Basket'>Units per Basket</option>
              <option value='Days Safe'>Days Safe</option>
              <option value='SMD'>SMD</option>
              <option value='Labor'>Labor</option>
              <option value='GET'>GET</option>
              <option value='LTSA'>LTSA</option>
              <option value='LTPA'>LTPA</option>
              <option value='Leads'>Leads</option>
              <option value='Measures'>Measures</option>
              <option value='HDPP - MA UA'>HDPP - MA UA</option>
              <option value='HDPP - GM UA'>HDPP - GM UA</option>
            </select>
          </div>
        </Grid.Column>
      </Grid>
      <Header textAlign="center">Region - {rgnNbr}, {metric} By District</Header>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        Data as of {dataAsOf}
      </div>
      <DataView testId={'salesByDistrict'}>
        <DataView.DataTable data={districtListDataObj} />
      </DataView>
    </>
  )
}


const propsByMetric = {
  Sales: {
    NOM_VP: '_SLS_VP',
    PCT_VP: '_SLS_AMT_VP_PCT',
    NOM_VLY: '_SLS_AMT_VLY',
    PCT_VLY: '_SLS_AMT_VLY_PCT',
    actual: '_SLS_AMT_ACT'
  },
  Units: {
    NOM_VP: null,
    PCT_VP: null,
    NOM_VLY: '_UNTS_VLY',
    PCT_VLY: '_UNTS_VLY_PCT',
    actual: '_UNTS_ACT'
  },
  Transactions: {
    NOM_VP: null,
    PCT_VP: null,
    NOM_VLY: '_TRNS_VLY',
    PCT_VLY: '_TRNS_VLY_PCT',
    actual: '_TRNS_ACT'
  },
  'Average Ticket': {
    NOM_VP: null,
    PCT_VP: null,
    NOM_VLY: '_AVG_TCKT_VLY',
    PCT_VLY: '_AVG_TCKT_VLY_PCT',
    actual: '_AVG_TCKT_ACT'
  },
  SMD: {
    NOM_VP: '_SMD_VP',
    PCT_VP: null,
    NOM_VLY: '_SMD_VLY',
    PCT_VLY: null,
    actual: '_SMD_ACT'
  },
  Labor: {
    NOM_VP: '_LBR_VF',
    PCT_VP: '_LBR_VF_PCT',
    NOM_VLY: null,
    PCT_VLY: null,
    actual: '_LBR_ACT'
  },
  GET: {
    NOM_VP: null,
    PCT_VP: null,
    NOM_VLY: '_GET_VLY',
    PCT_VLY: null,
    actual: '_GET_ACT'
  },
  LTSA: {
    NOM_VP: null,
    PCT_VP: null,
    NOM_VLY: '_LTSA_VLY',
    PCT_VLY: null,
    actual: '_LTSA_ACT'
  },
  LTPA: {
    NOM_VP: null,
    PCT_VP: null,
    NOM_VLY: '_ONLINE_SAT_VLY',
    PCT_VLY: null,
    actual: '_ONLINE_SAT_ACT'
  },
  Leads: {
    NOM_VP: null,
    PCT_VP: null,
    NOM_VLY: '_LEADS_VLY',
    PCT_VLY: '_LEADS_VLY_PCT',
    actual: '_LEADS_ACT'
  },
  Measures: {
    NOM_VP: null,
    PCT_VP: null,
    NOM_VLY: '_MEAS_VLY',
    PCT_VLY: '_MEAS_VLY_PCT',
    actual: '_MEAS_ACT'
  }
}