import { Header, Loader, Tab } from 'semantic-ui-react';
import { useRegionOverviewContext } from '../../../hooks/useRegionOverviewContext';
import { withOverviewHeader } from '../../HOC/withHeader';
import { RegionSalesByDistrictList } from './RegionSalesByDistrictList';
import { RegionOverviewTabs } from './RegionOverviewTabs';

const RegionBody = withOverviewHeader(RegionOverviewTabs, 'Region');
const DistrictBody = withOverviewHeader(RegionSalesByDistrictList, 'District');

// TODO: Replace component with your needed imported componen e.g. <MyComponent />;
const tabsMenu = {
  region: [
    {
      menuItem: 'Region',
      render: () => <RegionBody />
    },
    {
      menuItem: 'District List',
      render: () => <DistrictBody />
    }
  ],
  division: [
    {
      menuItem: 'Division',
      render: () => <RegionBody />
    },
    {
      menuItem: 'Region List',
      render: () => <DistrictBody />
    }
  ]
};

export const OverviewMenu = () => {
  const { isError, isRegionOverviewTabsLoading, level } = useRegionOverviewContext();
  if (isError) return <h1>Something went wrong</h1>;
  if (isRegionOverviewTabsLoading) return <Loader active>Loading</Loader>;
  if (false) {
    //TODO: replace value with correct validation field
    return <Header textAlign='center'>Invalid or Unauthorized District</Header>;
  }

  return <Tab panes={tabsMenu[level]} menu={{ secondary: true, pointing: true }} style={{ marginBottom: '1em' }} />;
};
