

// VOC Goals
export const VOC_LTPA_GOAL = 0.75
export const VOC_LTSA_GOAL = 0.80
export const VOC_GET_GOAL = 0.80
export const VOC_SHELF_AVAILABILITY_GOAL = 0.90
export const VOC_CASHIER_FRIENDLINESS_GOAL = 0.80


// OSA Goals
export const OSA_SIDEKICK_PURGE_GOAL = 0.98