import React, { useState } from 'react'
import { Header, Loader, Grid, Segment, Menu } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { UnitMenu } from '../Common/UnitMenu'
import { TimeframeDropdown } from '../Common/TimeframeDropdown'
import { TimeframeDropdownMon } from '../Common/TimeframeDropdownMon'

import { useQuery } from 'react-query'
import {
  getData
} from '../../service/DataService'
import { useQueryParams } from '../../hooks/useQueryParams'
import { getCompareStyle } from '../Common/TableCellUtils'
import { DataTable } from '../Common/DataTable'
import { DataView } from '../Common/DataView'

const METRIC_CONSTANTS = {
  district: {
    query: 'queryLeadsDtlByDst',
    METRIC_LEVEL: 'DST',
    subMetric: 'STR',
    urlLinkLevel: 'store',
    urlSubParam: 'strNbr',
    propToRender: 'STR_NBR',
    currentLevel: 'DST_NBR'
  },
  region: {
    query: 'queryLeadsDtlByRgn',
    METRIC_LEVEL: 'RGN',
    subMetric: 'DST',
    urlLinkLevel: 'district',
    urlSubParam: 'dstNbr',
    propToRender: 'DST_NBR',
    currentLevel: 'RGN_NBR'
  }
}


export const LeadsDetailDst = ({ timeframe, settimeframe, fpToggle, level, levelName }) => {

  const { dstNbr = 0, rgnNbr = 0 } = useQueryParams()

  const [unit, setUnit] = useState('NOM')
  const [allWebStore, setAllWebStore] = useState('') // All = empty string, Web = '_WEB', Store = '_STR'
  const queryParams = level === 'district' ? { dstNbr } : { locNbr: rgnNbr }
  const leadDetailQuery = useQuery([METRIC_CONSTANTS[level]['query'], { ...queryParams, fpToggle }], getData)

  if (leadDetailQuery.isLoading) {
    return <Loader active>Loading...</Loader>
  }

  let sumProgNmFilter = leadDetailQuery.data.filter((row) => {
    return row && row['SUMMARY_PROGRAM_NAME'] !== 'TOTAL' && row['METRIC_LEVEL'] === METRIC_CONSTANTS[level]['METRIC_LEVEL']
  })

  let totalFilter = leadDetailQuery.data.filter((row) => {
    return row && row['SUMMARY_PROGRAM_NAME'] === 'TOTAL' && row['METRIC_LEVEL'] === METRIC_CONSTANTS[level]['subMetric']
  })

  const recordByLevel = leadDetailQuery.data.filter(row => row && row['SUMMARY_PROGRAM_NAME'] === 'TOTAL' && row['METRIC_LEVEL'] === METRIC_CONSTANTS[level]['METRIC_LEVEL'])

  const totalRecords = [...recordByLevel, ...totalFilter]

  const isMonday = new Date().getDay() === 1

  const leadsBySumProgram = {
    headers: [
      {
        name: 'Summary Program Name '
      },
      { name: (isMonday && timeframe === 'WTD') ? 'TW Target ' : 'Target' },
      (isMonday && timeframe === 'WTD') ? { name: 'LW Target ' } : false,
      {
        name: 'Leads '
      },
      {
        name: unit === 'PCT' ? 'VLY% ' : 'VLY '
      }
    ].filter(row => Boolean(row)),
    data: sumProgNmFilter.map(row => [
      {
        stringValue: row['SUMMARY_PROGRAM_NAME']
      },
      { numValue: row[timeframe + allWebStore + '_LEADS_TGT'] },
      (isMonday && timeframe === 'WTD') && { numValue: row['LW' + allWebStore + '_LEADS_TGT'] },
      {
        numValue: row[timeframe + allWebStore + '_LEADS']
      },
      unit === 'NOM' ? {
        numValue: row[timeframe + allWebStore + '_VLY'],
        customStyle: getCompareStyle(row[timeframe + allWebStore + '_VLY'])
      } : {
        pctValue: row[timeframe + allWebStore + '_VLY_PCT'],
        customStyle: getCompareStyle(row[timeframe + allWebStore + '_VLY_PCT']),
        decimals: 2
      },
    ].filter(row => Boolean(row))
    ),
    sortable: true,
    sortableDefault: allWebStore === '' ? 0 : undefined

  }

  const totalLeadsDataObj = {
    headers: [
      { name: 'Org # ' },
      { name: (isMonday && timeframe === 'WTD') ? 'TW Target ' : 'Target ' },
      (isMonday && timeframe === 'WTD') ? { name: 'LW Target ' } : false,
      { name: 'Leads ' },
      { name: unit === 'PCT' ? 'VLY% ' : 'VLY ' },
    ].filter(row => Boolean(row)),
    data: totalRecords.map((row, index) => [
      {
        stringValue: METRIC_CONSTANTS[level][index === 0 ? 'METRIC_LEVEL' : 'subMetric'] + " " + row[METRIC_CONSTANTS[level][index === 0 ? 'currentLevel' : 'propToRender']],
        link: (index > 0) ? {
          to: `/${METRIC_CONSTANTS[level]['urlLinkLevel']}/overview`,
          params: {
            [METRIC_CONSTANTS[level]['urlSubParam']]: String(row[METRIC_CONSTANTS[level]['propToRender']]).padStart(4, '0')
          }
        } : undefined,
        orderBy: row[METRIC_CONSTANTS[level]['subMetric'] + '_NBR']
      },
      { numValue: row[timeframe + allWebStore + '_LEADS_TGT'] },
      (isMonday && timeframe === 'WTD') && { numValue: row['LW' + allWebStore + '_LEADS_TGT'] },
      {
        numValue: row[timeframe + allWebStore + '_LEADS']
      },
      unit === 'NOM' ? {
        numValue: row[timeframe + allWebStore + '_VLY'],
        customStyle: getCompareStyle(row[timeframe + allWebStore + '_VLY'])
      } : {
        pctValue: row[timeframe + allWebStore + '_VLY_PCT'],
        customStyle: getCompareStyle(row[timeframe + allWebStore + '_VLY_PCT']),
        decimals: 2
      },
    ].filter(row => Boolean(row))),
    sortable: true,
    sortableDefault: allWebStore === '' ? 0 : undefined
  }

  const sumProNmView = <>
    <Grid>
      <Grid.Column width={8}>
        <Grid.Row>Units:</Grid.Row>
        <Grid.Row>
          <UnitMenu unit={unit} setUnit={setUnit} />
        </Grid.Row>
      </Grid.Column>
      <Grid.Column width={8} textAlign='right'>
        <Grid.Row>&nbsp;</Grid.Row>
        <Grid.Row>
          <Menu compact size='small'>
            <Menu.Item
              name='All'
              active={allWebStore === ''}
              content='All'
              onClick={() => {
                setAllWebStore('')
              }}
            />
            <Menu.Item
              name='Web'
              active={allWebStore === '_WEB'}
              content='Web'
              onClick={() => {
                setAllWebStore('_WEB')
              }}
            />
            <Menu.Item
              name='Store'
              active={allWebStore === '_STR'}
              content='Store'
              onClick={() => {
                setAllWebStore('_STR')
              }}
            />
          </Menu>
        </Grid.Row>
      </Grid.Column>
    </Grid>
    {/* Leads overview - aggregated summary programs */}
    <br />
    <DataView testId='totalLeads'>
      <DataView.Title position='center'>Total Leads - {level} {rgnNbr || dstNbr}, {levelName} </DataView.Title>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        Data as of {totalRecords[0]?.TRNSFM_DT}
      </div>
      <DataView.DataTable data={totalLeadsDataObj} />
    </DataView>
    <p>*Target is reported as the total leads generated last year through the current period. For example, YTD target is all leads generated last year through the upcoming Sunday. Actual Lead counts and vLY are reported through the previous day.</p>
    {/* Leads detail - by individual summary program */}
    <Header textAlign='center'>Leads by Summary Program</Header>
    <DataTable data={leadsBySumProgram} />
  </>

  return (
    <>
      <Grid>
        <Grid.Column width={8}>
          <Grid.Row>&nbsp;</Grid.Row>
          <Grid.Row>
            <Link style={{ color: '#EE7125' }} to={`/${level}/overview?${level === "district" ? 'dstNbr' : 'rgnNbr'}=${rgnNbr || dstNbr}`} data-test='b2o-link' >Back to Overview</Link>
          </Grid.Row>
        </Grid.Column>
        <Grid.Column width={8}>
          <Grid.Row>Timeframe:</Grid.Row>
          <Grid.Row>
            {isMonday ?
              <TimeframeDropdownMon timeframe={timeframe} settimeframe={settimeframe} /> :
              <TimeframeDropdown timeframe={timeframe} settimeframe={settimeframe} />}
          </Grid.Row>
          <Grid.Row>&nbsp;</Grid.Row>
        </Grid.Column>
      </Grid>

      <Segment attached='bottom'>
        {sumProNmView}
      </Segment>
    </>
  )
}