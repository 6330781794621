import { createContext } from 'react';

import { useQuery } from 'react-query';
import { useQueryParams } from '../hooks/useQueryParams';

import { getData } from '../service/DataService';
import { OverviewMenu } from '../components/Overviews/Region/OverviewMenu';
import { useParams } from 'react-router-dom';

export const RegionOverviewContext = createContext();

const RegionOverviewContextProvider = ({ children, fpToggle, ...props }) => {
  const { rgnNbr, divNbr } = useQueryParams();
  const queryParam = rgnNbr || divNbr
  const { level } = useParams();
  const {
    data: salesDeptData = [],
    isError: isErrorSalesDept,
    isLoading: isLoadingSalesDept
  } = useQuery([`query/deptSales/region`, { fpToggle, locNbr: queryParam }], getData);
  const {
    data: deptInvData = [],
    isError: isErrorDeptInv,
    isLoading: isLoadingDepInv
  } = useQuery([`query/invDept/region`, { fpToggle, locNbr: rgnNbr }], getData);
  const {
    data: slsSclsByRgnData = [],
    isError: isErrorslsSclsByRgnData,
    isLoading: isLoadingslsSclsByRgnData
  } = useQuery(['querySlsSclsByRgn', { locNbr: rgnNbr, fpToggle }], getData);
  const {
    data: slsClsByRgnData = [],
    isError: isErrorSlsClsByRgn,
    isLoading: isLoadingSlsClsByRgn
  } = useQuery(['querySlsClsByRgn', { locNbr: rgnNbr, fpToggle }], getData);
  const {
    data: metricsByDistrict = [],
    isLoading: isLoadingMetrictsByDistrict,
    isError: isErrorMetrictsByDistrict
  } = useQuery(['querySwmDstMetricsByRgn', { locNbr: rgnNbr, fpToggle }], getData);
  const {
    data: swmRegionData = [],
    isError: isErrorSwmRegion,
    isLoading: isLoadingSwmRegion
  } = useQuery(['querySwmRegion', { locNbr: rgnNbr, fpToggle }], getData);
  const {
    data: SafetyIncOshaLtGlbiByRgnData = [],
    isError: isErrorSafetyIncOshaLtGlbiByRgn,
    isLoading: isLoadingSafetyIncOshaLtGlbiByRgn
  } = useQuery(['querySafetyIncOshaLtGlbiByRgn', { locNbr: rgnNbr, fpToggle }], getData);
  const {
    data: slsSkuRgnData = [],
    isError: isErrorSlsSkuRgn,
    isLoading: isLoadingSlsSkuRgn
  } = useQuery(['querySlsSkuByRgn', { locNbr: rgnNbr, fpToggle }], getData);

  const dataAsOf = metricsByDistrict[0]?.TRNSFM_DT;

  const value = {
    ...props,
    deptInvData,
    isError:
      isErrorSalesDept ||
      isErrorDeptInv ||
      isErrorMetrictsByDistrict ||
      isErrorslsSclsByRgnData ||
      isErrorSlsClsByRgn ||
      isErrorSwmRegion ||
      isErrorSafetyIncOshaLtGlbiByRgn ||
      isErrorSlsSkuRgn,
    isRegionOverviewTabsLoading:
      isLoadingSalesDept ||
      isLoadingDepInv ||
      isLoadingMetrictsByDistrict ||
      isLoadingslsSclsByRgnData ||
      isLoadingSlsClsByRgn ||
      isLoadingSwmRegion ||
      isLoadingSafetyIncOshaLtGlbiByRgn ||
      isLoadingSlsSkuRgn,
    rgnNbr,
    salesDeptData,
    slsSclsByRgnData,
    fpToggle,
    slsClsByRgnData,
    metricsByDistrict,
    swmRegionData,
    SafetyIncOshaLtGlbiByRgnData,
    level,
    divNbr,
    dataAsOf,
    slsSkuRgnData
  };

  return (
    <RegionOverviewContext.Provider value={value}>
      <OverviewMenu />
    </RegionOverviewContext.Provider>
  );
};

export default RegionOverviewContextProvider;
