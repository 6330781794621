import React, { useState, useEffect } from "react";
import { Header, Loader, Grid, Icon, Segment, Table } from 'semantic-ui-react';
import { SideKickPurgeToggle } from "./SideKickPurgeToggle";
import { useQuery } from 'react-query';
import { withRouter, Link } from 'react-router-dom/cjs/react-router-dom.min';
import { getData } from '../../service/DataService';
import { getPercentString } from "../Common/TableCellUtils";
import { OSA_SIDEKICK_PURGE_GOAL } from '../../constants/goals'

import { TimeframeDropdownLimited } from '../Common/TimeframeDropdownLimited'
import { TimeframeDropdownMonLimited } from '../Common/TimeframeDropdownMonLimited'

export const SideKickPurgeDst  = withRouter(({ timeframe, settimeframe, level, fpToggle, locNbr, location, ...props}) => {

    const [purgeOrBayDst, setPurgeOrBayDst] = useState('PURGE')
    const strNbr = locNbr
    const [sortColumn, setSortColumn] = useState("STR_NBR")
    const [sortDirection, setSortDirection] = useState("asecending")
    const [sortColumnPurge, setSortColumnPurge] = useState("STR_NBR")
    const [sortDirectionPurge, setSortDirectionPurge] = useState("asecending")
    const [sortColumnBay, setSortColumnBay] = useState("STR_NBR")
    const [sortDirectionBay, setSortDirectionBay] = useState("asecending")

    const search = new URLSearchParams(location.search)
    const dstNbr = search.has('dstNbr') ? search.get('dstNbr') : 0

    useEffect(() => {
        if (timeframe !== 'WTD' && timeframe !== 'LW') {
          settimeframe('WTD')
        }
      }, [timeframe])

    const osaSideKickPurgeDstQuery = useQuery([`query/queryOSASidekickSkuBayDst/${level}`, { dstNbr  , fpToggle }], getData)

    if (osaSideKickPurgeDstQuery.isLoading) {
        return (<Loader active>Loading</Loader>)
    }

    if (!osaSideKickPurgeDstQuery.data || osaSideKickPurgeDstQuery.data.length === 0) {
        return (<Header textAlign='center'>Invalid Store Number</Header>)
    }

    const osaSideKickPurgeDstData = osaSideKickPurgeDstQuery.data.filter(({DEPT}) => DEPT === '38')

    let osaSideKickPurgeDstDataFilterByPurge = osaSideKickPurgeDstData.filter((row) => {
        return row['TASK_TYPE'] === 'PURGE' 
    })

    let osaSideKickPurgeDstDataFilterByBay = osaSideKickPurgeDstData.filter((row) => {
        return row['TASK_TYPE'] === 'BAY' 
    })

    const lastTimeframeMap = {
        'WTD': 'LW',
        'LW': null, // We don't have comparisons for last week or month, so they'll be null
      }
    
    const ltf = lastTimeframeMap[timeframe]

    const sideKickPurgeData = osaSideKickPurgeDstDataFilterByPurge.reduce((acc, curr) => {
        let item = acc.find(item => item.STR_NBR === curr.STR_NBR);
        if (item) {
            item.STR_NBR = curr.STR_NBR
            item[timeframe + '_TOTAL_TASK_COUNT'] += curr[timeframe + '_TOTAL_TASK_COUNT']
            item[timeframe + '_COMPLETED_TASK_COUNT'] += curr[timeframe + '_COMPLETED_TASK_COUNT']
            item.ACTUAL = item[timeframe + '_COMPLETED_TASK_COUNT'] / item[timeframe + '_TOTAL_TASK_COUNT']  
            item[ltf + '_LY_TOTAL_TASK_COUNT'] += curr[ltf + '_LY_TOTAL_TASK_COUNT']
            item[ltf + '_LY_COMPLETED_TASK_COUNT'] += curr[ltf + '_LY_COMPLETED_TASK_COUNT']
            item.BPS = item[ltf + '_LY_TOTAL_TASK_COUNT'] !== 0 ? item[ltf + '_LY_COMPLETED_TASK_COUNT'] / item[ltf + '_LY_TOTAL_TASK_COUNT'] : '-'
        } else {
            acc.push({
                STR_NBR: curr.STR_NBR,
                [timeframe + '_TOTAL_TASK_COUNT']: curr[timeframe + '_TOTAL_TASK_COUNT'],
                [timeframe + '_COMPLETED_TASK_COUNT']: curr[timeframe + '_COMPLETED_TASK_COUNT'],
                ACTUAL: curr[timeframe + '_COMPLETED_TASK_COUNT'] / curr[timeframe + '_TOTAL_TASK_COUNT'],
                [ltf + '_LY_TOTAL_TASK_COUNT']: curr[ltf + '_LY_TOTAL_TASK_COUNT'],
                [ltf + '_LY_COMPLETED_TASK_COUNT']: curr[ltf + '_LY_COMPLETED_TASK_COUNT'],
                BPS: curr[ltf + '_LY_TOTAL_TASK_COUNT'] !== 0 ? curr[ltf + '_LY_COMPLETED_TASK_COUNT'] / curr[ltf + '_LY_TOTAL_TASK_COUNT'] : '-'
            })
        }
        return acc
    }, [])

    let sideKickPurgeDataSorted = sideKickPurgeData.sort((left, right) => {
        let result = left[sortColumn] - right[sortColumn]
        if (sortDirection === 'descending') {
            result *= -1
        }
        return result
    })

    const purgePackDown = osaSideKickPurgeDstDataFilterByPurge.reduce((acc, curr) => {
        let item = acc.find(item => item.STR_NBR === curr.STR_NBR);
        if (item) {
            item.STR_NBR = curr.STR_NBR
            item[timeframe + '_TOTAL_TASK_COUNT'] += curr[timeframe + '_TOTAL_TASK_COUNT']
            item[timeframe + '_COMPLETED_TASK_COUNT'] += curr[timeframe + '_COMPLETED_TASK_COUNT']
            item.RMNG = acc[timeframe + '_TOTAL_TASK_COUNT'] - acc[timeframe + '_COMPLETED_TASK_COUNT']
        } else {
            acc.push({
                STR_NBR: curr.STR_NBR,
                [timeframe + '_TOTAL_TASK_COUNT']: curr[timeframe + '_TOTAL_TASK_COUNT'],
                [timeframe + '_COMPLETED_TASK_COUNT']: curr[timeframe + '_COMPLETED_TASK_COUNT'],
                RMNG: curr[timeframe + '_TOTAL_TASK_COUNT'] - curr[timeframe + '_COMPLETED_TASK_COUNT']
            })
        }
        return acc
    }, [])

    let purgePackDownSorted = purgePackDown.sort((left, right) => {
        let result = left[sortColumnPurge] - right[sortColumnPurge]
        if (sortDirectionPurge === 'descending') {
            result *= -1
        }
        return result
    })

   const bayPackDown = osaSideKickPurgeDstDataFilterByBay.reduce((acc, curr) => {
        let item = acc.find(item => item.STR_NBR === curr.STR_NBR);
        if (item) {
            item.STR_NBR = curr.STR_NBR
            item[timeframe + '_TOTAL_TASK_COUNT'] += curr[timeframe + '_TOTAL_TASK_COUNT']
            item[timeframe + '_COMPLETED_TASK_COUNT'] += curr[timeframe + '_COMPLETED_TASK_COUNT']
            item.BayTaskComp = item[timeframe + '_TOTAL_TASK_COUNT'] !== 0 ? item[timeframe + '_COMPLETED_TASK_COUNT'] / item[timeframe + '_TOTAL_TASK_COUNT'] : '-'
        } else {
            acc.push({
                STR_NBR: curr.STR_NBR,
                [timeframe + '_TOTAL_TASK_COUNT']: curr[timeframe + '_TOTAL_TASK_COUNT'],
                [timeframe + '_COMPLETED_TASK_COUNT']: curr[timeframe + '_COMPLETED_TASK_COUNT'],
                BayTaskComp: curr[timeframe + '_TOTAL_TASK_COUNT'] !== 0 ?  curr[timeframe + '_COMPLETED_TASK_COUNT'] / curr[timeframe + '_TOTAL_TASK_COUNT'] : '-'
            })
        }
        return acc
    }, [])

    let bayPackDownSorted = bayPackDown.sort((left, right) => {
        let result = left[sortColumnBay] - right[sortColumnBay]
        if (sortDirectionBay === 'descending') {
            result *= -1
        }
        return result
    })
    
    return (
        <>
        <Segment>
        <Grid>
            <Grid.Column width={8}>
                <Grid.Row>
                <Link style={{ color: '#EE7125' }} to={`/${level}/overview?dstNbr=${locNbr}`} data-test='b2o-link'>Overview</Link>
                {' / '}
                <Link style={{ color: '#EE7125' }} to={`/osa?dstNbr=${locNbr}`} data-test='b2osa-link'>OSA</Link>
                {` / Purge`}
                </Grid.Row>
                <Grid.Row>&nbsp;</Grid.Row>
            </Grid.Column>      
            <Grid.Column width={7} />
            <Grid.Column width={7}>
                        Timeframe: 
              {new Date().getDay() === 1 ?
                <TimeframeDropdownMonLimited timeframe={timeframe} settimeframe={settimeframe} /> :
                <TimeframeDropdownLimited timeframe={timeframe} settimeframe={settimeframe} />}
            </Grid.Column>
        </Grid>

        <Header textAlign="center">Sidekick Execution - Purge</Header>
        <Table textAlign='center' unstackable celled sortable size='small'>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell
                    style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
                    sorted={sortColumn === 'STR_NBR' ? sortDirection : null}
                    onClick={() => {
                        if (sortColumn !== 'STR_NBR') {
                            setSortColumn('STR_NBR')
                            setSortDirection('ascending')
                        } else {
                            sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                            }
                        }}>Store {sortColumn !== 'STR_NBR' && <Icon fitted name='sort' />}</Table.HeaderCell>
                    <Table.HeaderCell 
                    style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
                    sorted={sortColumn === 'ACTUAL' ? sortDirection : null}
                    onClick={() => {
                        if (sortColumn !== 'ACTUAL') {
                            setSortColumn('ACTUAL')
                            setSortDirection('ascending')
                        } else {
                            sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                            }
                        }}>Actual {sortColumn !== 'ACTUAL' && <Icon fitted name='sort' />}</Table.HeaderCell>
                    <Table.HeaderCell
                    style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
                    sorted={sortColumn === 'Goal' ? sortDirection : null}
                    onClick={() => {
                        if (sortColumn !== 'Goal') {
                            setSortColumn('Goal')
                            setSortDirection('ascending')
                        } else {
                            sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                            }
                        }}>Goal {sortColumn !== 'Goal' && <Icon fitted name='sort' />}</Table.HeaderCell>
                    <Table.HeaderCell
                    style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
                    sorted={sortColumn === 'BPS' ? sortDirection : null}
                    onClick={() => {
                        if (sortColumn !== 'BPS') {
                            setSortColumn('BPS')
                            setSortDirection('ascending')
                        } else {
                            sortDirection === 'ascending' ? setSortDirection('descending') : setSortDirection('ascending')
                            }
                        }}>v{ltf} bps {sortColumn !== 'BPS' && <Icon fitted name='sort' />}</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {sideKickPurgeDataSorted.map((data) => {
                    return (
                        <Table.Row>
                            <Table.Cell><Link style={{ color: '#EE7125', textDecoration: 'underline' }} to={`/osa/Purge/store?strNbr=${data['STR_NBR']}`}>{data.STR_NBR}</Link></Table.Cell>
                            <Table.Cell style={{ color: data.ACTUAL < OSA_SIDEKICK_PURGE_GOAL ? 'red' : 'green'}}>{getPercentString(data.ACTUAL)}</Table.Cell>
                            <Table.Cell>{getPercentString(OSA_SIDEKICK_PURGE_GOAL)}</Table.Cell>
                            <Table.Cell>{data.BPS === '-' || isNaN(data.BPS) ? '-' : ((data.ACTUAL - data.BPS)*10000).toLocaleString(undefined, { maximumFractionDigits: 0 })}</Table.Cell>
                        </Table.Row>
                )})}
            </Table.Body>
        </Table>

        <Header textAlign="center">Purge Packdown by Stores</Header>
        <SideKickPurgeToggle purgeOrBay={purgeOrBayDst} setPurgeOrBay={setPurgeOrBayDst}/>
        <Table textAlign='center' unstackable celled sortable size='small'>
            <Table.Header>
                <Table.Row>
                    {purgeOrBayDst === "PURGE" &&<Table.HeaderCell
                    style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
                    sorted={sortColumnPurge === 'STR_NBR' ? sortDirectionPurge : null}
                    onClick={() => {
                        if (sortColumnPurge !== 'STR_NBR') {
                        setSortColumnPurge('STR_NBR')
                        setSortDirectionPurge('ascending')
                        } else {
                        sortDirectionPurge === 'ascending' ? setSortDirectionPurge('descending') : setSortDirectionPurge('ascending')
                        }}}>Store {sortColumnPurge !== 'STR_NBR' && <Icon fitted name='sort' />}</Table.HeaderCell>}
                    {purgeOrBayDst === "PURGE" &&<Table.HeaderCell
                    style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
                    sorted={sortColumnPurge === timeframe + '_COMPLETED_TASK_COUNT' ? sortDirectionPurge : null}
                    onClick={() => {
                        if (sortColumnPurge !== timeframe + '_COMPLETED_TASK_COUNT') {
                        setSortColumnPurge(timeframe + '_COMPLETED_TASK_COUNT')
                        setSortDirectionPurge('ascending')
                        } else {
                        sortDirectionPurge === 'ascending' ? setSortDirectionPurge('descending') : setSortDirectionPurge('ascending')
                        }}}>Comp Tasks {sortColumnPurge !== timeframe + '_COMPLETED_TASK_COUNT' && <Icon fitted name='sort' />}</Table.HeaderCell>}
                    {purgeOrBayDst === "PURGE" &&<Table.HeaderCell
                    style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
                    sorted={sortColumnPurge === 'RMNG' ? sortDirectionPurge : null}
                    onClick={() => {
                        if (sortColumnPurge !== 'RMNG') {
                        setSortColumnPurge('RMNG')
                        setSortDirectionPurge('ascending')
                        } else {
                        sortDirectionPurge === 'ascending' ? setSortDirectionPurge('descending') : setSortDirectionPurge('ascending')
                        }}}>Remaining Sent # {sortColumnPurge !== 'RMNG' && <Icon fitted name='sort' />}</Table.HeaderCell>}

                    {purgeOrBayDst === "BAY" &&<Table.HeaderCell
                    style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
                    sorted={sortColumnBay === 'STR_NBR' ? sortDirectionBay : null}
                    onClick={() => {
                        if (sortColumnBay !== 'STR_NBR') {
                        setSortColumnBay('STR_NBR')
                        setSortDirectionBay('ascending')
                        } else {
                        sortDirectionBay === 'ascending' ? setSortDirectionBay('descending') : setSortDirectionBay('ascending')
                        }}}>Store {sortColumnBay !== 'STR_NBR' && <Icon fitted name='sort' />}</Table.HeaderCell>}
                    {purgeOrBayDst === "BAY" &&<Table.HeaderCell
                    style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
                    sorted={sortColumnBay === timeframe + '_COMPLETED_TASK_COUNT' ? sortDirectionBay : null}
                    onClick={() => {
                        if (sortColumnBay !== timeframe + '_COMPLETED_TASK_COUNT') {
                        setSortColumnBay(timeframe + '_COMPLETED_TASK_COUNT')
                        setSortDirectionBay('ascending')
                        } else {
                        sortDirectionBay === 'ascending' ? setSortDirectionBay('descending') : setSortDirectionBay('ascending')
                        }}}>Bay Tasks Comp # {sortColumnBay !== timeframe + '_COMPLETED_TASK_COUNT' && <Icon fitted name='sort' />}</Table.HeaderCell>}
                    {purgeOrBayDst === "BAY" &&<Table.HeaderCell
                    style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
                    sorted={sortColumnBay === timeframe + '_TOTAL_TASK_COUNT' ? sortDirectionBay : null}
                    onClick={() => {
                        if (sortColumnBay !== timeframe + '_TOTAL_TASK_COUNT') {
                        setSortColumnBay(timeframe + '_TOTAL_TASK_COUNT')
                        setSortDirectionBay('ascending')
                        } else {
                        sortDirectionBay === 'ascending' ? setSortDirectionBay('descending') : setSortDirectionBay('ascending')
                        }}}>Bay Tasks Sent # {sortColumnBay !== timeframe + '_TOTAL_TASK_COUNT' && <Icon fitted name='sort' />}</Table.HeaderCell>}
                    {purgeOrBayDst === "BAY" &&<Table.HeaderCell
                    style={{ whiteSpace: "normal", wordWrap: 'break-word' }}
                    sorted={sortColumnBay === 'BayTaskComp' ? sortDirectionBay : null}
                    onClick={() => {
                        if (sortColumnBay !== 'BayTaskComp') {
                        setSortColumnBay('BayTaskComp')
                        setSortDirectionBay('ascending')
                        } else {
                        sortDirectionBay === 'ascending' ? setSortDirectionBay('descending') : setSortDirectionBay('ascending')
                        }}}>Bay Tasks Comp % {sortColumnBay !== 'BayTaskComp' && <Icon fitted name='sort' />}</Table.HeaderCell>}
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {purgePackDownSorted.map((data) => {
                    return (
                    <Table.Row>
                        {purgeOrBayDst === "PURGE" &&<Table.Cell><Link style={{ color: '#EE7125', textDecoration: 'underline' }} to={`/osa/Purge/store?strNbr=${data['STR_NBR']}`}>{data.STR_NBR}</Link></Table.Cell>}
                        {purgeOrBayDst === "PURGE" &&<Table.Cell>{data[timeframe + '_COMPLETED_TASK_COUNT']}</Table.Cell>}
                        {purgeOrBayDst === "PURGE" &&<Table.Cell>{data.RMNG}</Table.Cell>}
                    </Table.Row>
                )})}
                {bayPackDownSorted.map((data) => {
                    return (
                    <Table.Row>
                        {purgeOrBayDst === "BAY" &&<Table.Cell><Link style={{ color: '#EE7125', textDecoration: 'underline' }} to={`/osa/Purge/store?strNbr=${data['STR_NBR']}`}>{data.STR_NBR}</Link></Table.Cell>}
                        {purgeOrBayDst === "BAY" &&<Table.Cell>{data[timeframe + '_COMPLETED_TASK_COUNT']}</Table.Cell>}
                        {purgeOrBayDst === "BAY" &&<Table.Cell>{data[timeframe + '_TOTAL_TASK_COUNT']}</Table.Cell>}
                        {purgeOrBayDst === "BAY" &&<Table.Cell>{getPercentString(data.BayTaskComp)}</Table.Cell>}
                    </Table.Row>
                )})}
            </Table.Body>
        </Table>
        </Segment>
        </>
    )
})