import Cookies from 'universal-cookie';
import React, { useState, useEffect } from 'react';
import { Container, Loader } from 'semantic-ui-react';
import { withRouter, Route, Switch } from 'react-router-dom';
// import ReactGA from 'react-ga'
import ReactGA from 'react-ga4';
import './App.css';
import { ReactQueryDevtools } from 'react-query/devtools';
import { useQuery } from 'react-query';
import { getAdminData, getData } from './service/DataService';
import { EditAlert } from './components/Support/EditAlert';

// TODO: REMOVE THIS AFTER THE LEGACY PAGE IS SHOT DOWN
import { LegacyPageNotice } from './components/Support/LegacyPageNotice';
import { useWindowsDimensions } from './hooks/useWindowDimensions';

import { NewChangeLog } from './components/Support/NewChangeLog';
import { PageTables } from './components/Support/PageTables';

import { TopMenu } from './components/Menus/TopMenu';
import { LoginMenu } from './components/Menus/LoginMenu';
import { DeniedMenu } from './components/Menus/DeniedMenu';

import { Overview } from './components/Overviews/Overview';
import { DeptDrilldown } from './components/ProdHierDrilldown/DeptDrilldown';
import { ClassDrilldown } from './components/ProdHierDrilldown/ClassDrilldown';
import { SubclassDrilldown } from './components/ProdHierDrilldown/SubclassDrilldown';

import { AisleDrilldown } from './components/LocDrilldown/AisleDrilldown';
import { BayDrilldown } from './components/LocDrilldown/BayDrilldown';
import { DeptLocDrilldown } from './components/LocDrilldown/DeptLocDrilldown';
import { DeptLocAisleDrilldown } from './components/LocDrilldown/DeptLocAisleDrilldown';
import { DeptLocBayDrilldown } from './components/LocDrilldown/DeptLocBayDrilldown';
import { BaysByStore } from './components/LocDrilldown/BaysByStore';
import { BaysByStoreBayDrilldown } from './components/LocDrilldown/BaysByStoreBayDrilldown';

import { InvDeptDrilldown } from './components/InventoryDrilldown/InvDeptDrilldown';
import { InvClassDrilldown } from './components/InventoryDrilldown/InvClassDrilldown';
import { InvSubclassDrilldown } from './components/InventoryDrilldown/InvSubclassDrilldown';

import { SmdStrRsn } from './components/Details/SmdStrRsn';
import { SmdDstRsn } from './components/Details/SmdDstRsn';
import { SmdRgnRsn } from './components/Details/Region/SmdRgnRsn';
import { LaborDetail } from './components/Details/LaborDetail';
import { LaborDetailDst } from './components/Details/LaborDetailDst';
import { LtsaDetail } from './components/Details/LtsaDetail';
import { LtsaDetailDst } from './components/Details/LtsaDetailDst';
import { VocDetail } from './components/Details/VocDetail';
import { VocDetailDst } from './components/Details/VocDetailDst';
import { LeadsDetail } from './components/Details/LeadsDetail';
import { LeadsDetailDst } from './components/Details/LeadsDetailDst';
import { MeasDetail } from './components/Details/MeasDetail';
import { MeasDetailDst } from './components/Details/MeasDetailDst';
import { LeadsAssocView } from './components/Details/LeadsAssocView';
import { MeasAssocView } from './components/Details/MeasAssocView';

import { OrderFulfillment } from './components/OrderFulfillment/OrderFulfillment';
import { DstOrderFulfillment } from './components/OrderFulfillment/DstOrderFulfillment';
import { IntradayView } from './components/HourlyDaily/IntradayView';
import { IntradayDeptView } from './components/HourlyDaily/IntradayDeptView';
import { DailyView } from './components/HourlyDaily/DailyView';
import { DailyDeptView } from './components/HourlyDaily/DailyDeptView';
import { Credit } from './components/Credit/Credit';
import { ViewAlert } from './components/Support/ViewAlert';
import { ViewChangeLog } from './components/Support/ViewChangeLog';
import { SpecialtyProRental } from './components/SpecialtyProRental/SpecialtyProRental';

import { Shrink } from './components/Shrink/Shrink';
import { ShrinkAgedWc } from './components/Shrink/ShrinkAgedWc';
import { ShrinkReducedTag } from './components/Shrink/ShrinkReducedTag';
import { ShrinkCullMd } from './components/Shrink/ShrinkCullMd';
import { ShrinkDamagedMd } from './components/Shrink/ShrinkDamagedMd';
import { ShrinkRtvDeletedTag } from './components/Shrink/ShrinkRtvDeletedTag';
import { ShrinkBossCancel } from './components/Shrink/ShrinkBossCancel';
import { ShrinkOhEmptPkg } from './components/Shrink/ShrinkOhEmptPkg';
import { ShrinkOhEmptPkgDept } from './components/Shrink/ShrinkOhEmptPkgDept';
import { ShrinkInvalidScans } from './components/Shrink/ShrinkInvalidScans';
import { ShrinkRtvCredit } from './components/Shrink/ShrinkRtvCredit';
import { ShrinkClearanceNh } from './components/Shrink/ShrinkClearanceNh';
import { DstShrink } from './components/ShrinkDst/DstShrink';
import { DstShrinkAgedWc } from './components/ShrinkDst/DstShrinkAgedWc';
import { DstShrinkBossCancel } from './components/ShrinkDst/DstShrinkBossCancel';
import { DstShrinkClearanceNh } from './components/ShrinkDst/DstShrinkClearanceNh';
import { DstShrinkCullMd } from './components/ShrinkDst/DstShrinkCullMd';
import { DstShrinkDamagedMd } from './components/ShrinkDst/DstShrinkDamagedMd';
import { DstShrinkInvalidScans } from './components/ShrinkDst/DstShrinkInvalidScans';
import { DstShrinkOhEmptPkg } from './components/ShrinkDst/DstShrinkOhEmptPkg';
import { DstShrinkReducedTag } from './components/ShrinkDst/DstShrinkReducedTag';
import { DstShrinkRtvCredit } from './components/ShrinkDst/DstShrinkRtvCredit';

import { ShrinkStoreClass } from './components/Shrink/ShrinkStoreClass';
import { ShrinkStoreDept } from './components/Shrink/ShrinkStoreDept';
import { ShrinkStoreSubClass } from './components/Shrink/ShrinkStoreSubClass';
import { DstShrinkDept } from './components/ShrinkDst/DstShrinkDept';
import { DstShrinkClass } from './components/ShrinkDst/DstShrinkClass';
import { DstShrinkSubClass } from './components/ShrinkDst/DstShrinkSubClass';

import { DstShrinkRtvDeletedTag } from './components/ShrinkDst/DstShrinkRtvDeletedTag';
import { DstOhAutoRej } from './components/ShrinkDst/DstOhAutoRej';
import { DstAutoKR } from './components/ShrinkDst/DstAutoKR';
import { DstUnmatchedXfers } from './components/ShrinkDst/DstUnmatchedXfers';
import { Events } from './components/Events/Events';
import { EventsDetail } from './components/Events/EventsDetail';
import { HeadlinesInsights } from './components/HeadlinesInsights/HeadlinesInsights';
import { DstOverview } from './components/Overviews/DstOverview';
import { CreditDst } from './components/Credit/CreditDst';
import { SpecialtyProRentalDst } from './components/SpecialtyProRental/SpecialtyProRentalDst';
import { SafetyDetail } from './components/Details/SafetyDetail';
import { SafetyDetailDst } from './components/Details/SafetyDetailDst';
import { ServicesDtlStr } from './components/SpecialtyProRental/ServicesDtlStr';
import { ServicesDtlDst } from './components/SpecialtyProRental/ServicesDtlDst';
import { ServicesDtlDstPrgm } from './components/SpecialtyProRental/ServicesDtlDstPrgm';
import { SmdDetailStr } from './components/Details/SmdDetailStr';
import { SmdDetailDst } from './components/Details/SmdDetailDst';
import { SmdDetailRgn } from './components/Details/SmdDetailRgn';
import { SmdStrDept } from './components/Details/SmdStrDept';
import { SmdStrDeptRsn } from './components/Details/SmdStrDeptRsn';
import { SmdDstDept } from './components/Details/SmdDstDept';
import { SmdRgnDept } from './components/Details/Region/SmdRgnDept';
import { EventsDetailDst } from './components/Events/EventsDetailDst';
import { EventsDetailStr } from './components/Events/EventsDetailStr';
import { OSALanding } from './components/OSA/OSALanding';
import { OSAImaging } from './components/OSA/OSAImaging';
import { OSAImagingDept } from './components/OSA/OSAImagingDept';
import { OSAImagingDeptAisle } from './components/OSA/OSAImagingDeptAisle';
import { OSAImagingDist } from './components/OSA/OSAImagingDist';
import { OHMNoLocationTags } from './components/OSA/OHMNoLocationTags';
import { OHMNoLocationTagsByDept } from './components/OSA/OHMNoLocationTagsByDept';
import { OHMNoLocationTagsByDeptTag } from './components/OSA/OHMNoLocationTagsByDeptTag';
import { OHMNoLocationTagsDst } from './components/OSA/OHMNoLocationTagsDst';

import { SideKickSkuBay } from './components/OSA/SideKickSkuBay';
import { SideKickSkuBayDst } from './components/OSA/SideKickSkuBayDst';
import { SidekickSkuBayDept } from './components/OSA/SidekickSkuBayDept';
import { SidekickSkuBayBayDetail } from './components/OSA/SidekickSkuBayBayDetail';
import { SidekickSkuBayLoggedWork } from './components/OSA/SidekickSkuBayLoggedWork';
import { LaborDetailAssoc } from './components/Details/LaborDetailAssoc';
import { LaborDetailDeptByDst } from './components/Details/LaborDetailDeptByDst';
import { LaborDetailMgrDeptByDst } from './components/Details/LaborDetailMgrDeptByDst';
import { LaborDetailRgn } from './components/Details/LaborDetailRgn';
import { LaborDetailDeptManager } from './components/Details/Region/LaborDetailDeptManager';
import { ManagerAddedBaysDetails } from './components/OSA/ManagerAddedBaysDetails';
import { ManagerAddedBays } from './components/OSA/ManagerAddedBays';

import { SideKickPurge } from './components/OSA/SideKickPurge';
import { SideKickPurgeDeptPurge } from './components/OSA/SideKickPurgeDeptPurge';
import { SideKickPurgeDeptPurgeByBay } from './components/OSA/SideKickPurgeDeptPurgeByBay';
import { SideKickPurgeDeptBay } from './components/OSA/SideKickPurgeDeptBay';
import { SideKickPurgeDeptBayByBay } from './components/OSA/SideKickPurgeDeptBayByBay';
import { SideKickPurgeDst } from './components/OSA/SideKickPurgeDst';
import { useQueryParams } from './hooks/useQueryParams';
import { RegionVocSummary } from './components/Overviews/Region/RegionVocSummary';
import { EventsDetailRgn } from './components/Events/EventsDetailRgn';
import RegionOverviewContextProvider from './context/RegionOverviewContext';

const App = withRouter(({ location }) => {
  useEffect(() => {
    console.log(`Location changing: ${JSON.stringify(location)}`);
    // ReactGA.pageview(location.pathname); // Record a pageview for the given page
    ReactGA.send({ hitType: 'pageview', page: location.pathname }); // ReactGA4 version
  }, [location]);

  const { strNbr = 0, dstNbr = 0, rgnNbr = 0, divNbr = 0 } = useQueryParams();

  // TODO: Refactor to remove ternarys
  const [level, setLevel] = useState(
    strNbr ? 'store' : dstNbr ? 'district' : rgnNbr ? 'region' : divNbr ? 'division' : 'store'
  );
  const [levelSelection, setLevelSelection] = useState(
    strNbr ? 'store' : dstNbr ? 'district' : rgnNbr ? 'region' : divNbr ? 'division' : 'store'
  );
  const [storeNumber, setStoreNumber] = useState(strNbr || dstNbr || rgnNbr || divNbr || '');
  const [storeNumberField, setStoreNumberField] = useState(strNbr || dstNbr || rgnNbr || divNbr || '');
  const [timeframe, settimeframe] = useState('WTD');
  const [shrinkTimeframe, setShrinkTimeframe] = useState('LAST_INV');
  const [fpToggle, setFpToggle] = useState('storeops');
  const [clearedAlerts, setClearedAlerts] = useState(false);

  const [platformStatus, setPlatformStatus] = useState('loading');
  const [permissionDeniedRsn, setPDR] = useState('');

  const [userLdap, setUserLdap] = useState('');
  const [userIsCorporate, setUserIsCorporate] = useState(false);
  const [userGetsNPlus1, setUserGetsNPlus1] = useState(false);
  const [userMgtLvlId, setUserMgtLvlId] = useState(1000);
  const [userEmplCtgryNm, setUserEmplCtgryNm] = useState('');
  const [jobProfileNm, setJobProfileNm] = useState('');
  const [isLegacyAlertOpen, setIsLegacyAlertOpen] = useState(false);
  const [levelName, setLevelName] = useState({ RGN_NM: '', STR_NM: '', DST_NM: '', DIV_NM: '' })
  const [unit, setUnit] = useState('NOM');

  const adminQuery = useQuery(['queryGetStorePulseAlert'], getAdminData);
  const getName = useQuery(
    [`query/getLevelName/${level}`, { locNbr: strNbr || dstNbr || rgnNbr || divNbr, fpToggle }],
    getData
  );

  useEffect(() => {
    setStoreNumberField(strNbr || dstNbr || rgnNbr || divNbr || '');
    setLevelSelection(strNbr ? 'store' : dstNbr ? 'district' : rgnNbr ? 'region' : divNbr ? 'division' : 'store');
    setLevel(strNbr ? 'store' : dstNbr ? 'district' : rgnNbr ? 'region' : divNbr ? 'division' : 'store');
    setStoreNumber(strNbr || dstNbr || rgnNbr || divNbr || '');
  }, [location]);

  useEffect(() => {
    if (!getName.isLoading && getName.data.length > 0) {
      setLevelName(getName.data[0])
    }
  }, [getName.isLoading])

  useEffect(() => {
    if (window.MscJS !== undefined && window.MscJS !== null) {
      // We detected the android user agent. Assume that we're on Firstphone, but have a 20 second countdown to catch non-FP android users
      // setPlatformStatus("assumedFP")
      // let timeout = window.setTimeout(() => setPlatformStatus("assumedDesktop"), 20*1000)

      // Add the event listener for the First Phone platform load. This takes a few seconds, but less than 20
      // Update: deviceready is a Cordova event, and it is no longer needed with mobile sidecar
      // document.addEventListener("deviceready", () => {
      setPlatformStatus('FPLoaded');
      // window.clearTimeout(timeout)
      // }, false)
    } else {
      // We don't detect android, so we serve up the desktop experience (which serves desktop and other mobile platforms)
      console.log('Android test returned false', navigator.userAgent);
      setPlatformStatus('assumedDesktop');
    }
  }, []);

  // TODO: REMOVE THIS AFTER THE LEGACY PAGE IS SHOT DOWN
  const { isDesktop } = useWindowsDimensions();
  useEffect(() => {
    const LEGACY_PAGE = 'storewalk.homedepot.com';
    const LOCAL_PAGE = 'localhost:3000';
    const TESTING_PAGE = 'storewalk-np.homedepot.com';
    const isLegacyPage =
      window.location.host === LEGACY_PAGE || window.location.host === TESTING_PAGE;
    setIsLegacyAlertOpen(isLegacyPage);
  }, []);

  useEffect(() => {
    console.log(`handling status: ${platformStatus}`);
    if (platformStatus === 'FPLoaded') {
      // We're on the FP platform and it should be fully loaded
      // if (window.plugin && window.plugin.firstphone && window.plugin.firstphone.launcher) {
      if (window.MscJS !== undefined && window.MscJS !== null) {
        // console.log("Plugin exists")
        console.log('MscJS ready');
        // window.plugin.firstphone.launcher.getUser(userInfo => {
        const currentUserSessionData = JSON?.parse(
          window?.MscJS?.getCurrentUserSession() ?? '{"error":"Unknown Error, line 174"}'
        );
        const currentDeviceSessionData = JSON?.parse(
          window?.MscJS?.getCurrentDeviceSession() ?? '{"error":"Unknown Error, line 177"}'
        );
        if (
          currentUserSessionData &&
          currentUserSessionData?.status === 'success' &&
          currentDeviceSessionData &&
          currentDeviceSessionData?.status === 'success'
        ) {
          let currentUserSessionDataMessage = JSON?.parse(
            currentUserSessionData?.message ?? '{"message":"Unknown Error, line 182"}'
          );
          console.log('CurrentUserSession');
          console.log(currentUserSessionDataMessage);

          let currentDeviceSessionDataMessage = JSON?.parse(
            currentDeviceSessionData?.message ?? '{"message":"Unknown Error, line 186"}'
          );
          console.log('CurrentDeviceSession');
          console.log(currentDeviceSessionDataMessage);

          // if (userInfo && userInfo.thdSsoToken && userInfo.thdSsoToken !== "") {
          // if (datamessage && datamessage.thdSso && datamessage.thdSso !== "") {
          if (
            currentUserSessionDataMessage &&
            currentUserSessionDataMessage.pingUserAuthToken &&
            currentUserSessionDataMessage.pingUserAuthToken !== '' &&
            currentUserSessionDataMessage.pingUserRefreshToken &&
            currentUserSessionDataMessage.pingUserRefreshToken !== ''
            // && datamessage.thdSso && datamessage.thdSso !== ""
          ) {
            // new Cookies().set('THDSSO', datamessage?.thdSso, {
            //   domain: ".homedepot.com"
            // })
            setFpToggle('storeopsFP');
            setPlatformStatus('FPloggedIn');
          } else {
            setPlatformStatus('loggedOut');
          }

          //   setFpToggle('storeopsFP')
          //   setPlatformStatus("FPloggedIn")
          // } else {
          //   setPlatformStatus("assumedDesktop")
          // }

          // }, err => {
          //   console.log(`Error getting User Info: ${err}`)
          //   setPlatformStatus("assumedDesktop")
          // })

          // window.plugin.firstphone.launcher.getDevice(deviceInfo => {
          // window?.MscJS?.getCurrentUserSession(deviceInfo => {
          // console.log(deviceInfo)
          if (currentDeviceSessionDataMessage && currentDeviceSessionDataMessage.storeNumber) {
            setStoreNumber(currentDeviceSessionDataMessage.storeNumber);
            setStoreNumberField(currentDeviceSessionDataMessage.storeNumber);
          } else {
            console.log(`Problem getting location number. Assuming desktop`);
            setFpToggle('storeops');
            setPlatformStatus('assumedDesktop');
          }
          // }, err => console.log(`Error getting Device Info: ${err}`))
        }
      } else {
        // MSc not found, so assuming desktop.
        console.log('Mobile Sidecar not found. Assuming desktop');
        setFpToggle('storeops');
        setPlatformStatus('assumedDesktop');
      }
    } else if (platformStatus === 'assumedDesktop' || platformStatus === 'FPloggedIn') {
      fetch(`${process.env.REACT_APP_BACKEND}/api/${fpToggle}/loginCheck`, { credentials: 'include' })
        .then(resp => {
          if (resp.ok) {
            return resp.json();
          } else if (resp.status === 403) {
            return resp.text();
          } else {
            setPlatformStatus('loggedOut');
          }
        })
        .then(data => {
          if (data) {
            if (typeof data === 'string' || data instanceof String) {
              if (data.includes('Permission denied') || data.includes('Missing required permissions')) {
                setPlatformStatus('permissionDenied');
                setPDR(data);
              }
            } else {
              ReactGA.set({ userId: data.user });
              setUserLdap(data.user);
              setUserIsCorporate(data.iscorporate);
              setUserGetsNPlus1(data.getsnplus1);
              setUserMgtLvlId(data.mgtlvlid);
              setUserEmplCtgryNm(data.emplctgrynm);
              setJobProfileNm(data.jobprofilenm);
              setPlatformStatus('loggedIn');
            }
          } else {
            setPlatformStatus('loggedOut');
          }
        });
    }
  }, [platformStatus, fpToggle]);

  // TODO: We had to go with a non-blocking alert due to First Phones not being fully rolled out. Switch back to the blocking code when First Phones is fully rolled out.
  // if (isLegacyAlertOpen) {
  //   return <LegacyPageNotice />;
  // }

  switch (platformStatus) {
    case 'loading':
    case 'assumedFP':
    case 'FPLoaded':
    case 'FPloggedIn':
    case 'assumedDesktop':
      return <Loader active>Loading</Loader>;
    case 'permissionDenied':
      return <DeniedMenu pdr={permissionDeniedRsn} />;
    case 'loggedIn':
      return (
        <Container className='App' >
          {isLegacyAlertOpen && <LegacyPageNotice isSticky={isDesktop} />}
          <TopMenu
            level={level}
            setLevel={setLevel}
            levelSelection={levelSelection}
            setLevelSelection={setLevelSelection}
            storeNumber={storeNumber}
            setStoreNumber={setStoreNumber}
            storeNumberField={storeNumberField}
            setStoreNumberField={setStoreNumberField}
            fpToggle={fpToggle}
            location={location}
          />
          {!clearedAlerts && (
            <ViewAlert fpToggle={fpToggle} clearedAlerts={clearedAlerts} setClearedAlerts={setClearedAlerts} />
          )}

          <Switch>
            {/* Overview */}
            <Route exact path='/region/ltsa'>
              <RegionVocSummary
                level={level}
                fpToggle={fpToggle}
                locNbr={storeNumber}
                timeframe={timeframe}
                settimeframe={settimeframe}
              />
            </Route>
            <Route exact path='/store/overview'>
              <Overview
                timeframe={timeframe}
                settimeframe={settimeframe}
                fpToggle={fpToggle}
                userGetsNPlus1={userGetsNPlus1}
                userIsCorporate={userIsCorporate}
                userEmplCtgryNm={userEmplCtgryNm}
              />
            </Route>
            <Route exact path='/district/overview'>
              <DstOverview
                timeframe={timeframe}
                settimeframe={settimeframe}
                fpToggle={fpToggle}
                userEmplCtgryNm={userEmplCtgryNm}
              />
            </Route>
            <Route exact path='/:level/overview'>
              <RegionOverviewContextProvider
                fpToggle={fpToggle}
                timeframe={timeframe}
                settimeframe={settimeframe}
                userEmplCtgryNm={userEmplCtgryNm}
                unit={unit}
                setUnit={setUnit}
              />
            </Route>
            {/* Order Fulfillment */}
            <Route exact path='/store/orderfulfillment'>
              <OrderFulfillment timeframe={timeframe} settimeframe={settimeframe} fpToggle={fpToggle} />
            </Route>
            <Route exact path='/district/orderfulfillment'>
              <DstOrderFulfillment timeframe={timeframe} settimeframe={settimeframe} fpToggle={fpToggle} />
            </Route>
            {/* Intraday */}
            <Route exact path='/store/intraday'>
              <IntradayView fpToggle={fpToggle} level={level} />
            </Route>
            <Route exact path='/district/intraday'>
              <IntradayView fpToggle={fpToggle} level={level} />
            </Route>
            <Route exact path='/region/intraday'>
              <IntradayView fpToggle={fpToggle} level={level} />
            </Route>
            <Route exact path='/store/intraday/dept'>
              <IntradayDeptView fpToggle={fpToggle} level={level} />
            </Route>
            <Route exact path='/district/intraday/dept'>
              <IntradayDeptView fpToggle={fpToggle} level={level} />
            </Route>
            <Route exact path='/region/intraday/dept'>
              <IntradayDeptView fpToggle={fpToggle} level={level} />
            </Route>
            {/* Daily */}
            <Route exact path='/store/daily'>
              <DailyView fpToggle={fpToggle} level={level} levelName={String(levelName['STR_NM']).toLowerCase()} />
            </Route>
            <Route exact path='/district/daily'>
              <DailyView fpToggle={fpToggle} level={level} levelName={String(levelName['DST_NM']).toLowerCase()} />
            </Route>
            <Route exact path='/region/daily'>
              <DailyView fpToggle={fpToggle} level={level} levelName={String(levelName['RGN_NM']).toLowerCase()} />
            </Route>
            <Route exact path='/store/daily/dept'>
              <DailyDeptView fpToggle={fpToggle} level={level} />
            </Route>
            <Route exact path='/district/daily/dept'>
              <DailyDeptView fpToggle={fpToggle} level={level} />
            </Route>
            <Route exact path='/region/daily/dept'>
              <DailyDeptView fpToggle={fpToggle} level={level} />
            </Route>
            {/* Credit */}
            <Route exact path='/store/credit'>
              <Credit timeframe={timeframe} settimeframe={settimeframe} fpToggle={fpToggle} />
            </Route>
            <Route exact path='/district/credit'>
              <CreditDst timeframe={timeframe} settimeframe={settimeframe} fpToggle={fpToggle} />
            </Route>
            {/* Overview Metric Details */}
            <Route exact path='/store/safety'>
              <SafetyDetail
                timeframe={timeframe}
                settimeframe={settimeframe}
                userEmplCtgryNm={userEmplCtgryNm}
                fpToggle={fpToggle}
                levelName={String(levelName['STR_NM']).toLowerCase()}
              />
            </Route>
            <Route exact path='/district/safety'>
              <SafetyDetailDst
                timeframe={timeframe}
                settimeframe={settimeframe}
                userEmplCtgryNm={userEmplCtgryNm}
                fpToggle={fpToggle}
                level={level}
                levelName={String(levelName['DST_NM']).toLowerCase()}
              />
            </Route>
            <Route exact path='/region/safety'>
              <SafetyDetailDst
                timeframe={timeframe}
                settimeframe={settimeframe}
                userEmplCtgryNm={userEmplCtgryNm}
                fpToggle={fpToggle}
                level={level}
                levelName={String(levelName['RGN_NM']).toLowerCase()}
              />
            </Route>
            <Route exact path='/store/smd'>
              <SmdDetailStr timeframe={timeframe} settimeframe={settimeframe} fpToggle={fpToggle} />
            </Route>
            <Route exact path='/district/smd'>
              <SmdDetailDst timeframe={timeframe} settimeframe={settimeframe} fpToggle={fpToggle} />
            </Route>
            <Route exact path='/region/smd'>
              <SmdDetailRgn level={level} timeframe={timeframe} settimeframe={settimeframe} fpToggle={fpToggle} />
            </Route>

            <Route exact path='/store/smd/reason'>
              <SmdStrRsn fpToggle={fpToggle} />
            </Route>
            <Route exact path='/store/smd/dept/reason'>
              <SmdStrDeptRsn fpToggle={fpToggle} />
            </Route>
            <Route exact path='/district/smd/reason'>
              <SmdDstRsn timeframe={timeframe} settimeframe={settimeframe} fpToggle={fpToggle} />
            </Route>
            <Route exact path='/region/smd/reason'>
              <SmdRgnRsn timeframe={timeframe} settimeframe={settimeframe} fpToggle={fpToggle} />
            </Route>
            <Route exact path='/store/smd/dept'>
              <SmdStrDept timeframe={timeframe} settimeframe={settimeframe} fpToggle={fpToggle} />
            </Route>
            <Route exact path='/district/smd/dept'>
              <SmdDstDept timeframe={timeframe} settimeframe={settimeframe} fpToggle={fpToggle} />
            </Route>
            <Route exact path='/region/smd/dept'>
              <SmdRgnDept timeframe={timeframe} settimeframe={settimeframe} fpToggle={fpToggle} />
            </Route>
            <Route exact path='/store/labor'>
              <LaborDetail
                timeframe={timeframe}
                settimeframe={settimeframe}
                fpToggle={fpToggle}
                userEmplCtgryNm={userEmplCtgryNm}
                userMgtLvlId={userMgtLvlId}
                jobProfileNm={jobProfileNm}
                userIsCorporate={userIsCorporate}
              />
            </Route>

            <Route exact path='/store/laborDetailAssoc'>
              <LaborDetailAssoc
                timeframe={timeframe}
                settimeframe={settimeframe}
                fpToggle={fpToggle}
                userMgtLvlId={userMgtLvlId}
                userIsCorporate={userIsCorporate}
              />
            </Route>

            <Route exact path='/district/labor'>
              <LaborDetailDst
                timeframe={timeframe}
                settimeframe={settimeframe}
                fpToggle={fpToggle}
                userEmplCtgryNm={userEmplCtgryNm}
                levelName={String(levelName['DST_NM']).toLowerCase()}
              />
            </Route>

            <Route exact path='/district/laborDetail'>
              <LaborDetailDeptByDst timeframe={timeframe} settimeframe={settimeframe} fpToggle={fpToggle} />
            </Route>

            <Route exact path='/district/laborDetailManager'>
              <LaborDetailMgrDeptByDst timeframe={timeframe} settimeframe={settimeframe} fpToggle={fpToggle} />
            </Route>

            <Route exact path='/region/labor'>
              <LaborDetailRgn
                timeframe={timeframe}
                settimeframe={settimeframe}
                fpToggle={fpToggle}
                userEmplCtgryNm={userEmplCtgryNm}
                levelName={String(levelName['RGN_NM']).toLowerCase()}
              />
            </Route>

            <Route exact path='/region/laborDetailManager'>
              <LaborDetailDeptManager timeframe={timeframe} settimeframe={settimeframe} fpToggle={fpToggle} />
            </Route>

            <Route exact path='/store/ltsa'>
              <LtsaDetail timeframe={timeframe} settimeframe={settimeframe} fpToggle={fpToggle} />
            </Route>
            <Route exact path='/district/ltsa'>
              <LtsaDetailDst
                timeframe={timeframe}
                settimeframe={settimeframe}
                level={level}
                fpToggle={fpToggle}
                locNbr={storeNumber}
              />
            </Route>
            <Route exact path='/store/leads'>
              <LeadsDetail timeframe={timeframe} settimeframe={settimeframe} fpToggle={fpToggle} />
            </Route>
            <Route exact path='/district/leads'>
              <LeadsDetailDst timeframe={timeframe} settimeframe={settimeframe} level={level} fpToggle={fpToggle} levelName={String(levelName['DST_NM']).toLowerCase()}  />
            </Route>
            <Route exact path='/region/leads'>
              <LeadsDetailDst timeframe={timeframe} settimeframe={settimeframe} level={level} fpToggle={fpToggle} levelName={String(levelName['RGN_NM']).toLowerCase()} />
            </Route>
            <Route exact path='/store/leads/assoc'>
              <LeadsAssocView timeframe={timeframe} settimeframe={settimeframe} fpToggle={fpToggle} />
            </Route>
            <Route exact path='/store/meas'>
              <MeasDetail timeframe={timeframe} settimeframe={settimeframe} fpToggle={fpToggle} />
            </Route>
            <Route exact path='/district/meas'>
              <MeasDetailDst timeframe={timeframe} level={level} settimeframe={settimeframe} fpToggle={fpToggle} />
            </Route>
            <Route exact path='/region/meas'>
              <MeasDetailDst timeframe={timeframe} level={level} settimeframe={settimeframe} fpToggle={fpToggle} />
            </Route>
            <Route exact path='/store/meas/assoc'>
              <MeasAssocView timeframe={timeframe} settimeframe={settimeframe} fpToggle={fpToggle} />
            </Route>
            <Route exact path='/store/voc'>
              <VocDetail
                timeframe={timeframe}
                settimeframe={settimeframe}
                fpToggle={fpToggle}
                levelName={String(levelName['STR_NM']).toLowerCase()}
              />
            </Route>
            <Route exact path='/district/voc'>
              <VocDetailDst
                timeframe={timeframe}
                settimeframe={settimeframe}
                level={level}
                fpToggle={fpToggle}
                levelName={String(levelName['DST_NM']).toLowerCase()}
              />
            </Route>
            <Route exact path='/region/voc'>
              <VocDetailDst
                timeframe={timeframe}
                settimeframe={settimeframe}
                level={level}
                fpToggle={fpToggle}
                levelName={String(levelName['RGN_NM']).toLowerCase()}
              />
            </Route>
            {/* Sales Drilldowns by Prod Hier - NOTE: unreachable, to be retired, replaced by ./sales/. endpoints */}
            <Route exact path='/store/dept'>
              <DeptDrilldown timeframe={timeframe} settimeframe={settimeframe} level={level} fpToggle={fpToggle} />
            </Route>
            <Route exact path='/district/dept'>
              <DeptDrilldown timeframe={timeframe} settimeframe={settimeframe} level={level} fpToggle={fpToggle} />
            </Route>
            <Route exact path='/store/dept/class'>
              <ClassDrilldown timeframe={timeframe} settimeframe={settimeframe} level={level} fpToggle={fpToggle} />
            </Route>
            <Route exact path='/district/dept/class'>
              <ClassDrilldown timeframe={timeframe} settimeframe={settimeframe} level={level} fpToggle={fpToggle} />
            </Route>
            <Route exact path='/store/dept/class/subclass'>
              <SubclassDrilldown timeframe={timeframe} settimeframe={settimeframe} level={level} fpToggle={fpToggle} />
            </Route>
            <Route exact path='/district/dept/class/subclass'>
              <SubclassDrilldown timeframe={timeframe} settimeframe={settimeframe} level={level} fpToggle={fpToggle} />
            </Route>
            {/* Refined sales drilldown routes */}
            <Route exact path='/store/sales/dept'>
              <DeptDrilldown timeframe={timeframe} settimeframe={settimeframe} level='store' fpToggle={fpToggle} />
            </Route>
            <Route exact path='/district/sales/dept'>
              <DeptDrilldown timeframe={timeframe} settimeframe={settimeframe} level='district' fpToggle={fpToggle} />
            </Route>
            <Route exact path='/region/sales/dept'>
              <DeptDrilldown timeframe={timeframe} settimeframe={settimeframe} level='region' fpToggle={fpToggle} />
            </Route>
            <Route exact path='/store/sales/class'>
              <ClassDrilldown timeframe={timeframe} settimeframe={settimeframe} level='store' fpToggle={fpToggle} />
            </Route>
            <Route exact path='/district/sales/class'>
              <ClassDrilldown timeframe={timeframe} settimeframe={settimeframe} level='district' fpToggle={fpToggle} />
            </Route>
            <Route exact path='/region/sales/class'>
              <ClassDrilldown timeframe={timeframe} settimeframe={settimeframe} level='region' fpToggle={fpToggle} />
            </Route>
            <Route exact path='/store/sales/subclass'>
              <SubclassDrilldown timeframe={timeframe} settimeframe={settimeframe} level='store' fpToggle={fpToggle} />
            </Route>
            <Route exact path='/district/sales/subclass'>
              <SubclassDrilldown timeframe={timeframe} settimeframe={settimeframe} level='district' fpToggle={fpToggle} />
            </Route>
            {/* Aisle-Bay drilldowns */}
            <Route exact path='/store/aisle'>
              <AisleDrilldown timeframe={timeframe} settimeframe={settimeframe} fpToggle={fpToggle} />
            </Route>
            <Route exact path='/store/aisle/bay'>
              <BayDrilldown timeframe={timeframe} settimeframe={settimeframe} fpToggle={fpToggle} />
            </Route>
            <Route exact path='/store/deptLoc'>
              <DeptLocDrilldown timeframe={timeframe} settimeframe={settimeframe} fpToggle={fpToggle} />
            </Route>
            <Route exact path='/store/deptLoc/aisle'>
              <DeptLocAisleDrilldown timeframe={timeframe} settimeframe={settimeframe} fpToggle={fpToggle} />
            </Route>
            <Route exact path='/store/deptLoc/aisle/bay'>
              <DeptLocBayDrilldown timeframe={timeframe} settimeframe={settimeframe} fpToggle={fpToggle} />
            </Route>
            <Route exact path='/store/baysbystore'>
              <BaysByStore timeframe={timeframe} settimeframe={settimeframe} fpToggle={fpToggle} />
            </Route>
            <Route exact path='/store/baysbystore/bay'>
              <BaysByStoreBayDrilldown timeframe={timeframe} settimeframe={settimeframe} fpToggle={fpToggle} />
            </Route>
            {/* Inventory Drilldowns */}
            <Route exact path='/store/inventory/dept'>
              <InvDeptDrilldown level={level} fpToggle={fpToggle} />
            </Route>
            <Route exact path='/store/inventory/dept/class'>
              <InvClassDrilldown level={level} fpToggle={fpToggle} />
            </Route>
            <Route exact path='/store/inventory/dept/class/subclass'>
              <InvSubclassDrilldown level={level} fpToggle={fpToggle} />
            </Route>
            <Route exact path='/district/inventory/dept'>
              <InvDeptDrilldown level={level} fpToggle={fpToggle} />
            </Route>
            <Route exact path='/district/inventory/dept/class'>
              <InvClassDrilldown level={level} fpToggle={fpToggle} />
            </Route>
            <Route exact path='/district/inventory/dept/class/subclass'>
              <InvSubclassDrilldown level={level} fpToggle={fpToggle} />
            </Route>
            <Route exact path='/region/inventory/dept/class'>
              <InvClassDrilldown level={level} fpToggle={fpToggle} />
            </Route>
            <Route exact path='/region/inventory/dept'>
              <InvDeptDrilldown level={level} fpToggle={fpToggle} />
            </Route>

            {/* Shrink */}
            <Route exact path='/store/shrink'>
              <Shrink timeframe={shrinkTimeframe} settimeframe={setShrinkTimeframe} fpToggle={fpToggle} />
            </Route>
            <Route exact path='/district/shrink'>
              <DstShrink timeframe={shrinkTimeframe} settimeframe={setShrinkTimeframe} fpToggle={fpToggle} />
            </Route>
            <Route exact path='/store/shrink/invalidscans'>
              <ShrinkInvalidScans timeframe={shrinkTimeframe} settimeframe={setShrinkTimeframe} fpToggle={fpToggle} />
            </Route>
            <Route exact path='/district/shrink/invalidscans'>
              <DstShrinkInvalidScans timeframe={shrinkTimeframe} settimeframe={setShrinkTimeframe} fpToggle={fpToggle} />
            </Route>
            <Route exact path='/store/shrink/agedwc'>
              <ShrinkAgedWc fpToggle={fpToggle} />
            </Route>
            <Route exact path='/store/shrink/dept'>
              <ShrinkStoreDept fpToggle={fpToggle} />
            </Route>
            <Route exact path='/store/shrink/class'>
              <ShrinkStoreClass fpToggle={fpToggle} />
            </Route>
            <Route exact path='/store/shrink/subclass'>
              <ShrinkStoreSubClass fpToggle={fpToggle} />
            </Route>
            <Route exact path='/district/shrink/dept'>
              <DstShrinkDept fpToggle={fpToggle} />
            </Route>
            <Route exact path='/district/shrink/class'>
              <DstShrinkClass fpToggle={fpToggle} />
            </Route>
            <Route exact path='/district/shrink/subclass'>
              <DstShrinkSubClass fpToggle={fpToggle} />
            </Route>
            <Route exact path='/district/shrink/agedwc'>
              <DstShrinkAgedWc timeframe={shrinkTimeframe} settimeframe={setShrinkTimeframe} fpToggle={fpToggle} />
            </Route>
            <Route exact path='/store/shrink/clearancenh'>
              <ShrinkClearanceNh fpToggle={fpToggle} />
            </Route>
            <Route exact path='/district/shrink/clearancenh'>
              <DstShrinkClearanceNh timeframe={shrinkTimeframe} settimeframe={setShrinkTimeframe} fpToggle={fpToggle} />
            </Route>
            <Route exact path='/store/shrink/reducedtag'>
              <ShrinkReducedTag fpToggle={fpToggle} />
            </Route>
            <Route exact path='/district/shrink/reducedtag'>
              <DstShrinkReducedTag timeframe={shrinkTimeframe} settimeframe={setShrinkTimeframe} fpToggle={fpToggle} />
            </Route>
            <Route exact path='/store/shrink/cullmd'>
              <ShrinkCullMd timeframe={shrinkTimeframe} settimeframe={setShrinkTimeframe} fpToggle={fpToggle} />
            </Route>
            <Route exact path='/district/shrink/cullmd'>
              <DstShrinkCullMd timeframe={shrinkTimeframe} settimeframe={setShrinkTimeframe} fpToggle={fpToggle} />
            </Route>
            <Route exact path='/store/shrink/damagedmd'>
              <ShrinkDamagedMd timeframe={shrinkTimeframe} settimeframe={setShrinkTimeframe} fpToggle={fpToggle} />
            </Route>
            <Route exact path='/district/shrink/damagedmd'>
              <DstShrinkDamagedMd timeframe={shrinkTimeframe} settimeframe={setShrinkTimeframe} fpToggle={fpToggle} />
            </Route>
            <Route exact path='/store/shrink/rtvdeletedtag'>
              <ShrinkRtvDeletedTag timeframe={shrinkTimeframe} settimeframe={setShrinkTimeframe} fpToggle={fpToggle} />
            </Route>
            <Route exact path='/district/shrink/rtvdeletedtag'>
              <DstShrinkRtvDeletedTag timeframe={shrinkTimeframe} settimeframe={setShrinkTimeframe} fpToggle={fpToggle} />
            </Route>
            <Route exact path='/store/shrink/bosscancel'>
              <ShrinkBossCancel timeframe={shrinkTimeframe} settimeframe={setShrinkTimeframe} fpToggle={fpToggle} />
            </Route>
            <Route exact path='/district/shrink/bosscancel'>
              <DstShrinkBossCancel timeframe={shrinkTimeframe} settimeframe={setShrinkTimeframe} fpToggle={fpToggle} />
            </Route>
            <Route exact path='/store/shrink/ohemptpkg'>
              <ShrinkOhEmptPkg timeframe={shrinkTimeframe} settimeframe={setShrinkTimeframe} fpToggle={fpToggle} />
            </Route>
            <Route exact path='/store/shrink/ohemptpkg/dept'>
              <ShrinkOhEmptPkgDept timeframe={shrinkTimeframe} settimeframe={setShrinkTimeframe} fpToggle={fpToggle} />
            </Route>
            <Route exact path='/district/shrink/ohemptpkg'>
              <DstShrinkOhEmptPkg timeframe={shrinkTimeframe} settimeframe={setShrinkTimeframe} fpToggle={fpToggle} />
            </Route>
            <Route exact path='/store/shrink/rtvcredit'>
              <ShrinkRtvCredit timeframe={shrinkTimeframe} settimeframe={setShrinkTimeframe} fpToggle={fpToggle} />
            </Route>
            <Route exact path='/district/shrink/rtvcredit'>
              <DstShrinkRtvCredit timeframe={shrinkTimeframe} settimeframe={setShrinkTimeframe} fpToggle={fpToggle} />
            </Route>
            {/* Shrink Accuracy & Receiving */}
            <Route exact path='/district/ohautorej'>
              <DstOhAutoRej timeframe={shrinkTimeframe} settimeframe={setShrinkTimeframe} fpToggle={fpToggle} />
            </Route>
            <Route exact path='/district/autokr'>
              <DstAutoKR timeframe={shrinkTimeframe} settimeframe={setShrinkTimeframe} fpToggle={fpToggle} />
            </Route>
            <Route exact path='/district/unmatchedxfers'>
              <DstUnmatchedXfers timeframe={shrinkTimeframe} settimeframe={setShrinkTimeframe} fpToggle={fpToggle} />
            </Route>
            {/* Spec Pro Rental */}
            <Route exact path='/store/specialtyprorental'>
              <SpecialtyProRental timeframe={timeframe} settimeframe={settimeframe} fpToggle={fpToggle} />
            </Route>
            <Route exact path='/district/specialtyprorental'>
              <SpecialtyProRentalDst timeframe={timeframe} settimeframe={settimeframe} fpToggle={fpToggle} />
            </Route>
            <Route exact path='/store/services'>
              <ServicesDtlStr timeframe={timeframe} settimeframe={settimeframe} fpToggle={fpToggle} />
            </Route>
            <Route exact path='/district/services'>
              <ServicesDtlDst timeframe={timeframe} settimeframe={settimeframe} fpToggle={fpToggle} />
            </Route>
            <Route exact path='/district/services/program'>
              <ServicesDtlDstPrgm timeframe={timeframe} settimeframe={settimeframe} fpToggle={fpToggle} />
            </Route>
            {/* Events */}
            <Route exact path='/store/events'>
              <Events level='store' fpToggle={fpToggle} />
            </Route>
            <Route exact path='/district/events'>
              <Events level='district' fpToggle={fpToggle} />
            </Route>
            <Route exact path='/region/events'>
              <Events level='region' fpToggle={fpToggle} />
            </Route>

            <Route exact path='/store/events/detail'>
              <EventsDetailStr fpToggle={fpToggle} />
            </Route>
            <Route exact path='/region/events/detail'>
              <EventsDetailRgn fpToggle={fpToggle} />
            </Route>
            {/*<Route exact path='/store/events/detail'>
              <EventsDetail level={level} fpToggle={fpToggle} />
            </Route>*/}
            {/*<Route exact path='/district/events/detail'>
              <EventsDetail level={level} fpToggle={fpToggle} />
            </Route>*/}
            <Route exact path='/district/events/detail'>
              <EventsDetailDst fpToggle={fpToggle} />
            </Route>
            {/* Headlines Insights */}
            <Route exact path='/store/headlinesinsights'>
              <HeadlinesInsights level={level} fpToggle={fpToggle} />
            </Route>
            <Route exact path='/district/headlinesinsights'>
              <HeadlinesInsights level={level} fpToggle={fpToggle} />
            </Route>

            {/* OSA */}
            <Route exact path='/osa'>
              <OSALanding
                timeframe={timeframe}
                settimeframe={settimeframe}
                level={level}
                fpToggle={fpToggle}
                locNbr={storeNumber}
                userMgtLvlId={userMgtLvlId}
                userIsCorporate={userIsCorporate}
              />
            </Route>
            <Route exact path='/osa/district/imaging'>
              <OSAImagingDist
                timeframe={timeframe}
                settimeframe={settimeframe}
                level={level}
                fpToggle={fpToggle}
                locNbr={storeNumber}
              />
            </Route>
            <Route exact path='/osa/store/imaging'>
              <OSAImaging
                timeframe={timeframe}
                settimeframe={settimeframe}
                level={level}
                fpToggle={fpToggle}
                locNbr={storeNumber}
              />
            </Route>
            <Route exact path='/osa/imaging/dept'>
              <OSAImagingDept
                timeframe={timeframe}
                settimeframe={settimeframe}
                level={level}
                fpToggle={fpToggle}
                locNbr={storeNumber}
              />
            </Route>
            <Route exact path='/osa/imaging/dept/aisle'>
              <OSAImagingDeptAisle
                timeframe={timeframe}
                settimeframe={settimeframe}
                level={level}
                fpToggle={fpToggle}
                locNbr={storeNumber}
              />
            </Route>

            <Route exact path='/osa/ohmnoloc'>
              <OHMNoLocationTags
                timeframe={timeframe}
                settimeframe={settimeframe}
                level={level}
                fpToggle={fpToggle}
                locNbr={storeNumber}
              />
            </Route>
            <Route exact path='/osa/ohmnoloc/district'>
              <OHMNoLocationTagsDst
                timeframe={timeframe}
                settimeframe={settimeframe}
                level={level}
                fpToggle={fpToggle}
                locNbr={storeNumber}
              />
            </Route>
            <Route exact path='/osa/ohmnoloc/dept'>
              <OHMNoLocationTagsByDept
                timeframe={timeframe}
                settimeframe={settimeframe}
                level={level}
                fpToggle={fpToggle}
                locNbr={storeNumber}
              />
            </Route>
            <Route exact path='/osa/ohmnoloc/tag'>
              <OHMNoLocationTagsByDeptTag
                timeframe={timeframe}
                settimeframe={settimeframe}
                level={level}
                fpToggle={fpToggle}
                locNbr={storeNumber}
              />
            </Route>

            <Route exact path='/osa/Purge/store'>
              <SideKickPurge
                timeframe={timeframe}
                settimeframe={settimeframe}
                level={level}
                fpToggle={fpToggle}
                locNbr={storeNumber}
              />
            </Route>
            <Route exact path='/osa/Purge/deptPurge'>
              <SideKickPurgeDeptPurge
                timeframe={timeframe}
                settimeframe={settimeframe}
                level={level}
                fpToggle={fpToggle}
                locNbr={storeNumber}
              />
            </Route>
            <Route exact path='/osa/Purge/deptPurgeByBay'>
              <SideKickPurgeDeptPurgeByBay
                timeframe={timeframe}
                settimeframe={settimeframe}
                level={level}
                fpToggle={fpToggle}
                locNbr={storeNumber}
              />
            </Route>
            <Route exact path='/osa/Purge/deptBay'>
              <SideKickPurgeDeptBay
                timeframe={timeframe}
                settimeframe={settimeframe}
                level={level}
                fpToggle={fpToggle}
                locNbr={storeNumber}
              />
            </Route>
            <Route exact path='/osa/Purge/deptBayByBay'>
              <SideKickPurgeDeptBayByBay
                timeframe={timeframe}
                settimeframe={settimeframe}
                level={level}
                fpToggle={fpToggle}
                locNbr={storeNumber}
              />
            </Route>
            <Route exact path='/osa/Purge/district'>
              <SideKickPurgeDst
                timeframe={timeframe}
                settimeframe={settimeframe}
                level={level}
                fpToggle={fpToggle}
                locNbr={storeNumber}
              />
            </Route>

            <Route exact path='/osa/sidekickskubay/store'>
              <SideKickSkuBay
                timeframe={timeframe}
                settimeframe={settimeframe}
                level={level}
                fpToggle={fpToggle}
                locNbr={storeNumber}
              />
            </Route>
            <Route exact path='/osa/sidekickskubay/district'>
              <SideKickSkuBayDst
                timeframe={timeframe}
                settimeframe={settimeframe}
                level={level}
                fpToggle={fpToggle}
                locNbr={storeNumber}
              />
            </Route>

            <Route exact path='/osa/sidekickskubay/dept'>
              <SidekickSkuBayDept
                timeframe={timeframe}
                settimeframe={settimeframe}
                level={level}
                fpToggle={fpToggle}
                locNbr={storeNumber}
              />
            </Route>
            <Route exact path='/osa/sidekickskubay/dept/baypkdn'>
              <SidekickSkuBayBayDetail
                timeframe={timeframe}
                settimeframe={settimeframe}
                level={level}
                fpToggle={fpToggle}
                locNbr={storeNumber}
              />
            </Route>
            <Route exact path='/osa/sidekickskubay/logged'>
              <SidekickSkuBayLoggedWork
                level={level}
                timeframe={timeframe}
                locNbr={storeNumber}
                fpToggle={fpToggle}
                settimeframe={settimeframe}
              />
            </Route>
            <Route exact path='/osa/manageraddedbays'>
              <ManagerAddedBays
                timeframe={timeframe}
                settimeframe={settimeframe}
                level={level}
                fpToggle={fpToggle}
                locNbr={storeNumber}
              />
            </Route>
            <Route exact path='/osa/manageraddedbays/baydetails'>
              <ManagerAddedBaysDetails
                timeframe={timeframe}
                settimeframe={settimeframe}
                level={level}
                fpToggle={fpToggle}
                locNbr={storeNumber}
              />
            </Route>
          </Switch>

          <ReactQueryDevtools initialIsOpen={false} />
          <ViewChangeLog fpToggle={fpToggle} />
          {fpToggle === 'storeops' && adminQuery.isSuccess && (
            <>
              <EditAlert />
              <NewChangeLog />
              <PageTables />
            </>
          )}
          {/* <Scanner /> */}
          {isLegacyAlertOpen && <LegacyPageNotice isSticky={isDesktop} isBottom />}
        </Container>
      );
    case 'loggedOut':
      return <LoginMenu />;
    default:
      // return <LoginMenu />
      return <Loader active>Loading</Loader>;
  }
});

export default App;
