import { TimeframeMenu } from '../Menus/TimeFrameMenu';
import { useRegionOverviewContext } from '../../hooks/useRegionOverviewContext';
import { Header } from 'semantic-ui-react';
import { useState } from 'react';

export const withOverviewHeader = (WrappedComponent, name) => {
  return () => {
    const { timeframe, settimeframe, unit, setUnit, dataAsOf, rgnNbr, levelName, metricsByDistrict, SafetyIncOshaLtGlbiByRgnData } = useRegionOverviewContext();
    const [metric, setMetric] = useState('Sales');
    return (
      <>
        <TimeframeMenu timeframe={timeframe} settimeframe={settimeframe} unit={unit} setUnit={setUnit} showUnits />
        {String(name).toLowerCase() === 'region' ? (
          <>
            <Header textAlign='center' style={{ textTransform: 'capitalize' }}>
              Overview - {name} {rgnNbr}, {levelName}
            </Header>

            <div style={{ display: 'flex', justifyContent: 'center' }}>
              Data as of {dataAsOf}
            </div>
          </>
        ) : <></>}
        <WrappedComponent
          timeframe={timeframe}
          metricsByDistrict={metricsByDistrict}
          unit={unit}
          SafetyIncOshaLtGlbiByRgnData={SafetyIncOshaLtGlbiByRgnData}
          dataAsOf={dataAsOf}
          setMetric={setMetric}
          metric={metric}
          rgnNbr={rgnNbr}
        />
      </>
    );
  };
};
